import {
  ReportApiUnauthorizedAction,
  ReportGenericApiErrorAction,
  ClearErrorsAction,
} from '@store/application/types';
import { APPLICATION_ACTION_TYPES } from '@store/application/actionTypes';

export function reportApiUnauthorized(type:string, errors:string[]):ReportApiUnauthorizedAction {
  return {
    type: APPLICATION_ACTION_TYPES.API_UNAUTHORIZED,
    action: type,
    errors,
  };
}

export function reportGenericApiError(type:string, errors:string[]):ReportGenericApiErrorAction {
  return {
    type: APPLICATION_ACTION_TYPES.API_ERROR,
    action: type,
    errors,
  };
}

export function clearErrors():ClearErrorsAction {
  return {
    type: APPLICATION_ACTION_TYPES.CLEAR_ERRORS,
  };
}
