import { ajax, AjaxResponse } from 'rxjs/ajax';
import { of, ObservableInput, forkJoin } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  switchMap, map, catchError,
} from 'rxjs/operators';

import { fetchRecords } from '@store/catalog/records/actions';
import { fetchLists } from '@store/catalog/lists/actions';
import { APPLICATION_ACTION_TYPES } from '@store/application/actionTypes';
import { fetchProfile } from '@store/profile/profile/actions';
import { fetchNotifications } from '@store/notifications/actions';
import CONFIG from '@config/environments/base';
import {
  LoginAction,
  LogoutAction,
  ForgotPasswordAction,
  ResetPasswordAction,
} from '@store/authentication/types';
import { AUTHENTICATION_ACTION_TYPES } from '@store/authentication/actionTypes';
import { fetchAuthHeaders, handleAPIErrors } from '@store/application/APIHelper';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import { fetchOrganizations } from '@store/organizations/organizations/actions';
import Cookies from 'js-cookie';
import {
  destroySession, forgotPasswordSuccess, sessionCreated, sessionLoaded,
} from './actions';

export const catchApiUnauthorizedEpic = (
  action$:any,
) => action$.pipe(
  ofType(APPLICATION_ACTION_TYPES.API_UNAUTHORIZED),
  switchMap(() => of(destroySession('unauthorized'))),
);

const loadSessionEpic = (
  action$:any,
) => action$.pipe(
  ofType(AUTHENTICATION_ACTION_TYPES.SESSION.LOAD),
  map(() => {
    const data = {
      apiKey: Cookies.get('apiKey') !== undefined ? JSON.parse(Cookies.get('apiKey')) : undefined,
    };
    if (data.apiKey !== undefined) {
      return sessionLoaded(data);
    }
    return destroySession('unauthorized');
  }),
);

const sessionLoadedEpic = (
  action$:any,
) => action$.pipe(
  ofType(AUTHENTICATION_ACTION_TYPES.SESSION.LOADED, AUTHENTICATION_ACTION_TYPES.SESSION.CREATED),
  switchMap(() => of(
    fetchProfile(),
    fetchOrganizations(),
    fetchRecords(),
    fetchLists(),
    fetchNotifications(),
  )),
);

const performLoginEpic = (
  action$:any,
) => action$.pipe(
  ofType(AUTHENTICATION_ACTION_TYPES.LOGIN),
  switchMap((action:LoginAction) => ajax({
    url: `${CONFIG.AUTH_URL}/auth/`,
    method: 'POST',
    body: action.credentials,
  }).pipe(
    map((response:AjaxResponse) => sessionCreated(response.response)),
    catchError((error):ObservableInput<Action> => handleAPIErrors(action, error)),
  )),
);

const performLogoutEpic = (
  action$:any,
  state$:RootStateOrAny,
) => action$.pipe(
  ofType(AUTHENTICATION_ACTION_TYPES.LOGOUT),
  switchMap((action:LogoutAction) => forkJoin([
    ajax({
      url: `${CONFIG.AUTH_URL}/auth/`,
      method: 'DELETE',
      headers: fetchAuthHeaders(state$),
    }),
  ])
    .pipe(
      map(() => destroySession('destroyed')),
      catchError((error):ObservableInput<Action> => handleAPIErrors(action, error)),
    )),
);

const forgotPasswordEpic = (
  action$:any,
) => action$.pipe(
  ofType(AUTHENTICATION_ACTION_TYPES.FORGOT_PASSWORD),
  switchMap((action:ForgotPasswordAction) => ajax({
    url: `${CONFIG.AUTH_URL}/auth/password`,
    method: 'POST',
    body: action.data,
  }).pipe(
    map(() => forgotPasswordSuccess()),
    catchError((error):ObservableInput<Action> => handleAPIErrors(action, error)),
  )),
);

const resetPasswordEpic = (
  action$:any,
  state$:RootStateOrAny,
) => action$.pipe(
  ofType(AUTHENTICATION_ACTION_TYPES.RESET_PASSWORD),
  switchMap((action:ResetPasswordAction) => ajax({
    url: `${CONFIG.AUTH_URL}/auth/password`,
    method: 'PUT',
    headers: fetchAuthHeaders(state$),
    body: action.data,
  }).pipe(
    map((response:AjaxResponse) => sessionCreated(response)),
    catchError((error):ObservableInput<Action> => handleAPIErrors(action, error)),
  )),
);

const confirmEmailEpic = (
  action$:any,
  state$:any,
) => action$.pipe(
  ofType(AUTHENTICATION_ACTION_TYPES.CONFIRM_EMAIL),
  switchMap((action:ResetPasswordAction):ObservableInput<any> => ajax({
    url: `${CONFIG.AUTH_URL}/auth/confirm-email/`,
    method: 'PUT',
    headers: fetchAuthHeaders(state$),
    body: action.data,
  }).pipe(
    map((response:AjaxResponse) => sessionCreated(response)),
    catchError((error):ObservableInput<Action> => handleAPIErrors(action, error)),
  )),
);

export default [
  loadSessionEpic,
  sessionLoadedEpic,
  performLoginEpic,
  performLogoutEpic,
  forgotPasswordEpic,
  resetPasswordEpic,
  confirmEmailEpic,
];
