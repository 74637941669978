import { PaginatedAPIResponse } from '@store/application/types';
import { CIRCULATION_ACTION_TYPES } from '@store/circulation//actionTypes';
import { Fee, FeeAction } from '@store/circulation//fees/types';
import { Hold, HoldAction } from '@store/circulation//holds/types';
import { Location, LocationAction } from '@store/circulation//locations/types';
import { RecordLease, RecordLeaseAction } from '@store/circulation//recordLeases/types';
import { Section, SectionAction } from '@store/circulation//sections/types';
import { Transfer, TransferAction } from '@store/circulation//transfers/types';

export interface FeesState extends PaginatedAPIResponse {
  searchResults: Fee[]
  fees: Fee[]
  currentFee: Fee
  loading: boolean
}

const initialFeesState:FeesState = {
  searchResults: [],
  fees: [],
  currentFee: undefined,
  loading: false,
  currentPage: undefined,
  totalPages: undefined,
  pageSize: undefined,
  results: undefined,
};

export const fees = (
  state:FeesState = initialFeesState,
  action:FeeAction = undefined,
):FeesState => {
  switch (action.type) {
    case CIRCULATION_ACTION_TYPES.FEES.FETCH:
      return {
        ...state,
        fees: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CIRCULATION_ACTION_TYPES.FEES.FETCHED:
      return {
        ...state,
        fees: action.fees,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CIRCULATION_ACTION_TYPES.FEE.FETCHED:
    case CIRCULATION_ACTION_TYPES.FEE.UPDATED:
      return { ...state, currentFee: action.fee };
    case CIRCULATION_ACTION_TYPES.FEE.CREATED:
      return { ...state, fees: [...state.fees, action.fee] };
    case CIRCULATION_ACTION_TYPES.FEE.DESTROYED:
    case CIRCULATION_ACTION_TYPES.FEE.UPDATE:
    case CIRCULATION_ACTION_TYPES.FEE.CREATE:
      return { ...state, currentFee: undefined };
    default:
      return state;
  }
};

export interface HoldsState extends PaginatedAPIResponse {
  searchResults: Hold[]
  holds: Hold[]
  currentHold: Hold
  loading: boolean
}

const initialHoldsState:HoldsState = {
  searchResults: [],
  holds: [],
  currentHold: undefined,
  loading: false,
  currentPage: undefined,
  totalPages: undefined,
  pageSize: undefined,
  results: undefined,
};

export const holds = (
  state:HoldsState = initialHoldsState,
  action:HoldAction = undefined,
):HoldsState => {
  switch (action.type) {
    case CIRCULATION_ACTION_TYPES.HOLDS.FETCH:
      return {
        ...state,
        holds: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CIRCULATION_ACTION_TYPES.HOLDS.FETCHED:
      return {
        ...state,
        holds: action.holds,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CIRCULATION_ACTION_TYPES.HOLD.FETCHED:
    case CIRCULATION_ACTION_TYPES.HOLD.UPDATED:
      return { ...state, currentHold: action.hold };
    case CIRCULATION_ACTION_TYPES.HOLD.CREATED:
      return { ...state, holds: [...state.holds, action.hold] };
    case CIRCULATION_ACTION_TYPES.HOLD.DESTROYED:
    case CIRCULATION_ACTION_TYPES.HOLD.UPDATE:
    case CIRCULATION_ACTION_TYPES.HOLD.CREATE:
      return { ...state, currentHold: undefined };
    default:
      return state;
  }
};

export interface TransfersState extends PaginatedAPIResponse {
  searchResults: Transfer[]
  transfers: Transfer[]
  currentTransfer: Transfer
  loading: boolean
}

const initialTransfersState:TransfersState = {
  searchResults: [],
  transfers: [],
  currentTransfer: undefined,
  loading: false,
  currentPage: undefined,
  totalPages: undefined,
  pageSize: undefined,
  results: undefined,
};

export const transfers = (
  state:TransfersState = initialTransfersState,
  action:TransferAction = undefined,
):TransfersState => {
  switch (action.type) {
    case CIRCULATION_ACTION_TYPES.TRANSFERS.FETCH:
      return {
        ...state,
        transfers: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CIRCULATION_ACTION_TYPES.TRANSFERS.FETCHED:
      return {
        ...state,
        transfers: action.transfers,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CIRCULATION_ACTION_TYPES.TRANSFER.FETCHED:
    case CIRCULATION_ACTION_TYPES.TRANSFER.UPDATED:
      return { ...state, currentTransfer: action.transfer };
    case CIRCULATION_ACTION_TYPES.TRANSFER.CREATED:
      return { ...state, transfers: [...state.transfers, action.transfer] };
    case CIRCULATION_ACTION_TYPES.TRANSFER.DESTROYED:
    case CIRCULATION_ACTION_TYPES.TRANSFER.UPDATE:
    case CIRCULATION_ACTION_TYPES.TRANSFER.CREATE:
      return { ...state, currentTransfer: undefined };
    default:
      return state;
  }
};

export interface RecordLeasesState extends PaginatedAPIResponse {
  searchResults: RecordLease[]
  recordLeases: RecordLease[]
  currentRecordLease: RecordLease
  loading: boolean
}

const initialRecordLeasesState:RecordLeasesState = {
  searchResults: [],
  recordLeases: [],
  currentRecordLease: undefined,
  loading: false,
  currentPage: undefined,
  totalPages: undefined,
  pageSize: undefined,
  results: 0,
};

export const recordLeases = (
  state:RecordLeasesState = initialRecordLeasesState,
  action:RecordLeaseAction = undefined,
):RecordLeasesState => {
  switch (action.type) {
    case CIRCULATION_ACTION_TYPES.LEASES.FETCH:
    case CIRCULATION_ACTION_TYPES.LEASES.CLEAR:
      return {
        ...state,
        recordLeases: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        searchResults: [],
        loading: true,
      };
    case CIRCULATION_ACTION_TYPES.LEASES.FETCHED:
      return {
        ...state,
        recordLeases: action.leases,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CIRCULATION_ACTION_TYPES.LEASES.SEARCH:
    case CIRCULATION_ACTION_TYPES.LEASES.CLEAR_SEARCH:
      return { ...state, searchResults: [] };
    case CIRCULATION_ACTION_TYPES.LEASES.SEARCHED:
      return { ...state, searchResults: action.results };
    case CIRCULATION_ACTION_TYPES.LEASE.FETCHED:
    case CIRCULATION_ACTION_TYPES.LEASE.UPDATED:
      return { ...state, currentRecordLease: action.lease };
    case CIRCULATION_ACTION_TYPES.LEASE.CREATED:
      return { ...state, recordLeases: [...state.recordLeases, action.lease] };
    case CIRCULATION_ACTION_TYPES.LEASE.CLEAR:
    case CIRCULATION_ACTION_TYPES.LEASE.DESTROYED:
    case CIRCULATION_ACTION_TYPES.LEASE.UPDATE:
    case CIRCULATION_ACTION_TYPES.LEASE.CREATE:
      return { ...state, currentRecordLease: undefined };
    default:
      return state;
  }
};

export interface LocationsState extends PaginatedAPIResponse {
  searchResults: Location[]
  locations: Location[]
  currentLocation: Location
  loading: boolean
}

const initialLocationsState:LocationsState = {
  searchResults: [],
  locations: [],
  currentLocation: undefined,
  loading: false,
  currentPage: undefined,
  totalPages: undefined,
  pageSize: undefined,
  results: undefined,
};

export const locations = (
  state:LocationsState = initialLocationsState,
  action:LocationAction = undefined,
):LocationsState => {
  switch (action.type) {
    case CIRCULATION_ACTION_TYPES.LOCATIONS.FETCH:
      return {
        ...state,
        locations: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CIRCULATION_ACTION_TYPES.LOCATIONS.FETCHED:
      return {
        ...state,
        locations: action.locations,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CIRCULATION_ACTION_TYPES.LOCATION.FETCHED:
    case CIRCULATION_ACTION_TYPES.LOCATION.UPDATED:
      return { ...state, currentLocation: action.location };
    case CIRCULATION_ACTION_TYPES.LOCATION.CREATED:
      return { ...state, locations: [...state.locations, action.location] };
    case CIRCULATION_ACTION_TYPES.LOCATION.DESTROYED:
    case CIRCULATION_ACTION_TYPES.LOCATION.UPDATE:
    case CIRCULATION_ACTION_TYPES.LOCATION.CREATE:
      return { ...state, currentLocation: undefined };
    default:
      return state;
  }
};

export interface SectionsState extends PaginatedAPIResponse {
  searchResults: Section[]
  sections: Section[]
  currentSection: Section
  loading: boolean
}

const initialSectionsState:SectionsState = {
  searchResults: [],
  sections: [],
  currentSection: undefined,
  loading: false,
  currentPage: undefined,
  totalPages: undefined,
  pageSize: undefined,
  results: undefined,
};

export const sections = (
  state:SectionsState = initialSectionsState,
  action:SectionAction = undefined,
):SectionsState => {
  switch (action.type) {
    case CIRCULATION_ACTION_TYPES.SECTIONS.FETCH:
      return {
        ...state,
        sections: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case CIRCULATION_ACTION_TYPES.SECTIONS.FETCHED:
      return {
        ...state,
        sections: action.sections,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case CIRCULATION_ACTION_TYPES.SECTION.FETCHED:
    case CIRCULATION_ACTION_TYPES.SECTION.UPDATED:
      return { ...state, currentSection: action.section };
    case CIRCULATION_ACTION_TYPES.SECTION.CREATED:
      return { ...state, sections: [...state.sections, action.section] };
    case CIRCULATION_ACTION_TYPES.SECTION.DESTROYED:
    case CIRCULATION_ACTION_TYPES.SECTION.UPDATE:
    case CIRCULATION_ACTION_TYPES.SECTION.CREATE:
      return { ...state, currentSection: undefined };
    default:
      return state;
  }
};
