/**
 * This file exists for typescript's benefit only.  During compilation,  webpack will overwrite
 * CONFIG with the actual config object for the given environment.
 */

const SampleConfig = {
  AUTH_URL: '',
  CATALOG_URL: '',
  CIRCULATION_URL: '',
  PROCUREMENT_URL: '',
  NOTIFICATIONS_URL: '',
  MOCK_API_URL: '',
  CLIENT_URL: '',
  GOOGLE_BOOKS_KEY: '',
};

// CONFIG will get overwritten by the webpack DefinePlugin.
declare let CONFIG: typeof SampleConfig;

export default CONFIG;
