interface INOTIFICATION_ACTION_TYPES {
  NOTIFICATIONS: {
    FETCH: 'disembark/notifications/fetch_notifications',
    FETCHED: 'disembark/notifications/notifications_fetched'
  },
  NOTIFICATION: {
    FETCH: 'disembark/notification/fetch_notification',
    FETCHED: 'disembark/notification/notification_fetched',
    CREATE: 'disembark/notification/create_notification',
    CREATED: 'disembark/notification/notification_created',
    UPDATE: 'disembark/notification/update_notification',
    UPDATED: 'disembark/notification/notification_updated',
    DESTROY: 'disembark/notification/destroy_notification',
    DESTROYED: 'disembark/notification/notification_destroyed'
  }
}

export const NOTIFICATION_ACTION_TYPES:INOTIFICATION_ACTION_TYPES = {
  NOTIFICATIONS: {
    FETCH: 'disembark/notifications/fetch_notifications',
    FETCHED: 'disembark/notifications/notifications_fetched',
  },
  NOTIFICATION: {
    FETCH: 'disembark/notification/fetch_notification',
    FETCHED: 'disembark/notification/notification_fetched',
    CREATE: 'disembark/notification/create_notification',
    CREATED: 'disembark/notification/notification_created',
    UPDATE: 'disembark/notification/update_notification',
    UPDATED: 'disembark/notification/notification_updated',
    DESTROY: 'disembark/notification/destroy_notification',
    DESTROYED: 'disembark/notification/notification_destroyed',
  },
};

export default NOTIFICATION_ACTION_TYPES;
