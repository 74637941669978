import { PaginatedAPIResponse } from '@store/application/types';
import { NOTIFICATION_ACTION_TYPES } from '@store/notifications/actionTypes';
import { Notification, NotificationAction } from '@store/notifications/types';

interface NotificationsState extends PaginatedAPIResponse {
  searchResults: Notification[]
  notifications: Notification[]
  currentNotification: Notification
  loading: boolean
}

const initialNotificationsState:NotificationsState = {
  searchResults: [],
  notifications: [],
  currentNotification: undefined,
  loading: false,
};

export function notifications(
  state:NotificationsState = initialNotificationsState,
  action:NotificationAction = undefined,
) {
  switch (action.type) {
    case NOTIFICATION_ACTION_TYPES.NOTIFICATIONS.FETCH:
      return {
        ...state,
        notifications: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case NOTIFICATION_ACTION_TYPES.NOTIFICATIONS.FETCHED:
      return {
        ...state,
        notifications: action.notifications,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case NOTIFICATION_ACTION_TYPES.NOTIFICATION.FETCHED:
    case NOTIFICATION_ACTION_TYPES.NOTIFICATION.UPDATED:
      return { ...state, currentNotification: action.notification };
    case NOTIFICATION_ACTION_TYPES.NOTIFICATION.CREATED:
      return { ...state, notifications: [...state.notifications, action.notification] };
    case NOTIFICATION_ACTION_TYPES.NOTIFICATION.DESTROYED:
    case NOTIFICATION_ACTION_TYPES.NOTIFICATION.UPDATE:
    case NOTIFICATION_ACTION_TYPES.NOTIFICATION.CREATE:
      return { ...state, currentNotification: undefined };
    default:
      return state;
  }
}

export default notifications;
