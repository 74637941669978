import { ajax, AjaxResponse } from 'rxjs/ajax';
import { ObservableInput } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, map, catchError } from 'rxjs/operators';
import { PROFILE_ACTION_TYPES } from '@store/profile/actionTypes';
import { fetchAuthHeaders, handleAPIErrors } from '@store/application/APIHelper';
import {
  profileCreated, profileDestroyed, profileFetched, profileUpdated,
} from '@store/profile/profile/actions';
import { CreateProfileAction, DestroyProfileAction, UpdateProfileAction } from '@store/profile/profile/types';
import CONFIG from '@config/environments/base';
import { Action } from 'redux';

const fetchProfileEpic = (action$:any, state$:any) => action$.pipe(
  ofType(PROFILE_ACTION_TYPES.PROFILE.FETCH),
  switchMap((action) => ajax({
    url: `${CONFIG.AUTH_URL}/me`,
    method: 'GET',
    headers: fetchAuthHeaders(state$),
  }).pipe(
    map((response:AjaxResponse) => profileFetched(response.response)),
    catchError((error):any => { handleAPIErrors(action, error); }),
  )),
);

const createProfileEpic = (action$:any) => action$.pipe(
  ofType(PROFILE_ACTION_TYPES.PROFILE.CREATE),
  switchMap((action:CreateProfileAction):ObservableInput<any> => ajax({
    url: `${CONFIG.AUTH_URL}/me`,
    method: 'POST',
    body: action.credentials,
  }).pipe(
    map(() => profileCreated()),
    catchError((error):ObservableInput<Action> => handleAPIErrors(action, error)),
  )),
);

const updateProfileEpic = (action$:any, state$:any) => action$.pipe(
  ofType(PROFILE_ACTION_TYPES.PROFILE.UPDATE),
  switchMap((action:UpdateProfileAction) => ajax({
    url: `${CONFIG.AUTH_URL}/me`,
    method: 'PUT',
    headers: fetchAuthHeaders(state$),
    body: action.data,
  }).pipe(
    map((response:AjaxResponse) => profileUpdated(response.response)),
    catchError((error):ObservableInput<Action> => handleAPIErrors(action, error)),
  )),
);

const destroyProfileEpic = (action$:any, state$:any) => action$.pipe(
  ofType(PROFILE_ACTION_TYPES.PROFILE.DESTROY),
  switchMap((action:DestroyProfileAction) => ajax({
    url: `${CONFIG.AUTH_URL}/me`,
    method: 'DELETE',
    headers: fetchAuthHeaders(state$),
    body: action.data,
  })
    .pipe(
      map(() => {
        profileDestroyed();
      }),
      catchError((error):ObservableInput<Action> => handleAPIErrors(action, error)),
    )),
);

export default [
  fetchProfileEpic,
  createProfileEpic,
  updateProfileEpic,
  destroyProfileEpic,
];
