import { PaginatedAPIResponse } from '@store/application/types';
import { ProfileFetchedAction } from '@store/profile/profile/types';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';
import { Admin, AdminAction } from '@store/organizations/admins/types';
import { Branch, BranchAction } from '@store/organizations/branches/types';
import { Organization, OrganizationAction } from '@store/organizations/organizations/types';

export interface OrganizationsState extends PaginatedAPIResponse {
  searchResults: Organization[]
  organizations: Organization[]
  currentOrganization: Organization
  loading: boolean
  alternativeShortName?: string
}

const initialOrganizationsState:OrganizationsState = {
  searchResults: [],
  organizations: [],
  currentOrganization: undefined,
  loading: false,
  alternativeShortName: undefined,
};

type OrganizationReducerAction = OrganizationAction | ProfileFetchedAction;

export function organizations(
  state:OrganizationsState = initialOrganizationsState,
  action:OrganizationReducerAction = undefined,
) {
  switch (action.type) {
    case ORGANIZATION_ACTION_TYPES.ORGANIZATIONS.FETCH:
      return {
        ...state,
        organizations: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case ORGANIZATION_ACTION_TYPES.ORGANIZATIONS.FETCHED:
      return {
        ...state,
        organizations: action.organizations,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        currentOrganization: action.organizations[0],
        loading: false,
      };
    case ORGANIZATION_ACTION_TYPES.ORGANIZATION.FETCHED:
    case ORGANIZATION_ACTION_TYPES.ORGANIZATION.UPDATED:
      return { ...state, currentOrganization: action.organization };
    // case PROFILE_ACTION_TYPES.PROFILE.FETCHED:
    //   return { ...state, currentOrganization: action.response.defaultOrganization };
    case ORGANIZATION_ACTION_TYPES.ORGANIZATION.CREATED:
      return { ...state, organizations: [...state.organizations, action.organization] };
    case ORGANIZATION_ACTION_TYPES.ORGANIZATION.DESTROYED:
    case ORGANIZATION_ACTION_TYPES.ORGANIZATION.UPDATE:
    case ORGANIZATION_ACTION_TYPES.ORGANIZATION.CREATE:
      return { ...state, currentOrganization: undefined };
    case ORGANIZATION_ACTION_TYPES.ORGANIZATION.CHECK_SHORT_NAME:
      return { ...state, alternativeShortName: undefined };
    case ORGANIZATION_ACTION_TYPES.ORGANIZATION.SHORT_NAME_CHECKED:
      return { ...state, alternativeShortName: action.alternative };
    default:
      return state;
  }
}

export interface BranchesState extends PaginatedAPIResponse {
  searchResults: Branch[]
  branches: Branch[]
  currentBranch: Branch
  loading: boolean
}

const initialBranchesState:BranchesState = {
  searchResults: [],
  branches: [],
  currentBranch: undefined,
  loading: false,
};

export function branches(
  state:BranchesState = initialBranchesState,
  action:BranchAction = undefined,
) {
  switch (action.type) {
    case ORGANIZATION_ACTION_TYPES.BRANCHES.FETCH:
      return {
        ...state,
        branches: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case ORGANIZATION_ACTION_TYPES.BRANCHES.FETCHED:
      return {
        ...state,
        branches: action.branches,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case ORGANIZATION_ACTION_TYPES.BRANCHES.SEARCHED:
      return { ...state, searchResults: action.branches };
    case ORGANIZATION_ACTION_TYPES.BRANCH.FETCHED:
    case ORGANIZATION_ACTION_TYPES.BRANCH.UPDATED:
      return { ...state, currentBranch: action.branch };
    case ORGANIZATION_ACTION_TYPES.BRANCH.CREATED:
      return { ...state, branches: [...state.branches, action.branch] };
    case ORGANIZATION_ACTION_TYPES.BRANCH.DESTROYED:
    case ORGANIZATION_ACTION_TYPES.BRANCH.UPDATE:
    case ORGANIZATION_ACTION_TYPES.BRANCH.CLEAR:
    case ORGANIZATION_ACTION_TYPES.BRANCH.CREATE:
      return { ...state, currentBranch: undefined };
    default:
      return state;
  }
}

export interface AdminsState extends PaginatedAPIResponse {
  searchResults: Admin[]
  admins: Admin[]
  currentAdmin:Admin
  loading: boolean
}

const initialAdminsState:AdminsState = {
  searchResults: [],
  admins: [],
  currentAdmin: undefined,
  loading: false,
};

export function admins(state:AdminsState = initialAdminsState, action:AdminAction = undefined) {
  switch (action.type) {
    case ORGANIZATION_ACTION_TYPES.STAFF_ADMINS.FETCH:
      return {
        ...state,
        admins: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case ORGANIZATION_ACTION_TYPES.STAFF_ADMINS.FETCHED:
      return {
        ...state,
        admins: action.admins,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.FETCHED:
    case ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.UPDATED:
      return { ...state, currentAdmin: action.admin };
    case ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.CREATED:
      return { ...state, admins: [...state.admins, action.admin] };
    case ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.DESTROYED:
    case ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.UPDATE:
    case ORGANIZATION_ACTION_TYPES.STAFF_ADMIN.CREATE:
      return { ...state, currentAdmin: undefined };
    default:
      return state;
  }
}
