// import { PaginatedAPIResponse } from '@store/application/types';
import { ORGANIZATION_ACTION_TYPES } from '@store/organizations/actionTypes';
import {
  CheckOrganizationShortNameAction,
  CreateOrganizationAction,
  DestroyOrganizationAction,
  FetchOrganizationAction,
  FetchOrganizationsAction,
  FetchOrganizationsOptions,
  Organization,
  OrganizationCreatedAction,
  OrganizationDestroyedAction,
  OrganizationFetchedAction,
  OrganizationsFetchedAction,
  OrganizationShortNameCheckedAction,
  OrganizationUpdatedAction,
  UpdateOrganizationAction,
} from '@store/organizations/organizations/types';

export const fetchOrganizations = (
  options:FetchOrganizationsOptions = undefined,
):FetchOrganizationsAction => ({
  type: ORGANIZATION_ACTION_TYPES.ORGANIZATIONS.FETCH,
  options,
});
// interface OrganizationsFetchedResponse extends PaginatedAPIResponse {
//   data: Organization[]
// }
export const organizationsFetched = (
  response:Organization[],
):OrganizationsFetchedAction => ({
  type: ORGANIZATION_ACTION_TYPES.ORGANIZATIONS.FETCHED,
  pageSize: 1,
  currentPage: 1,
  totalPages: 1,
  results: 1,
  organizations: response,
});

export const fetchOrganization = (id:number|string):FetchOrganizationAction => ({
  type: ORGANIZATION_ACTION_TYPES.ORGANIZATION.FETCH,
  id,
});

export const organizationFetched = (organization:Organization):OrganizationFetchedAction => ({
  type: ORGANIZATION_ACTION_TYPES.ORGANIZATION.FETCHED,
  organization,
});

export const createOrganization = (organization:FormData):CreateOrganizationAction => ({
  type: ORGANIZATION_ACTION_TYPES.ORGANIZATION.CREATE,
  organization,
});

export const organizationCreated = (organization:Organization):OrganizationCreatedAction => ({
  type: ORGANIZATION_ACTION_TYPES.ORGANIZATION.CREATED,
  organization,
  id: organization.id,
});

export const updateOrganization = (organization:FormData):UpdateOrganizationAction => ({
  type: ORGANIZATION_ACTION_TYPES.ORGANIZATION.UPDATE,
  organization,
  id: organization.get('id') as string,
});

export const organizationUpdated = (organization:Organization):OrganizationUpdatedAction => ({
  type: ORGANIZATION_ACTION_TYPES.ORGANIZATION.UPDATED,
  organization,
  id: organization?.id,
});

export const destroyOrganization = (data:FormData):DestroyOrganizationAction => ({
  type: ORGANIZATION_ACTION_TYPES.ORGANIZATION.DESTROY,
  data,
});

export function organizationDestroyed(id: number|string): OrganizationDestroyedAction {
  return ({
    type: ORGANIZATION_ACTION_TYPES.ORGANIZATION.DESTROYED,
    id,
  });
}

export function checkOrganizationShortName(shortName: string): CheckOrganizationShortNameAction {
  return ({
    type: ORGANIZATION_ACTION_TYPES.ORGANIZATION.CHECK_SHORT_NAME,
    shortName,
  });
}

export const organizationShortNameChecked = (
  alternative:string = undefined,
):OrganizationShortNameCheckedAction => ({
  type: ORGANIZATION_ACTION_TYPES.ORGANIZATION.SHORT_NAME_CHECKED,
  alternative,
});
