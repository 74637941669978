import { PROFILE_ACTION_TYPES } from '@store/profile/actionTypes';
import {
  CreateAssociationAction,
  DestroyAssociationAction,
  FetchAssociationAction,
  FetchAssociationsAction,
  Association,
  AssociationCreatedAction,
  AssociationDestroyedAction,
  AssociationFetchedAction,
  AssociationsFetchedAction,
  AssociationUpdatedAction,
  UpdateAssociationAction,
  FetchAssociationsOptions,
} from '@store/profile/associations/types';

export const fetchAssociations = (
  options:FetchAssociationsOptions = undefined,
):FetchAssociationsAction => ({
  type: PROFILE_ACTION_TYPES.ASSOCIATIONS.FETCH,
  options,
});

export const associationsFetched = (
  response:Association[],
):AssociationsFetchedAction => ({
  type: PROFILE_ACTION_TYPES.ASSOCIATIONS.FETCHED,
  associations: response,
});

export const fetchAssociation = (id:number|string):FetchAssociationAction => ({
  type: PROFILE_ACTION_TYPES.ASSOCIATION.FETCH,
  id,
});

export const associationFetched = (association:Association):AssociationFetchedAction => ({
  type: PROFILE_ACTION_TYPES.ASSOCIATION.FETCHED,
  association,
});

export const createAssociation = (association:FormData):CreateAssociationAction => ({
  type: PROFILE_ACTION_TYPES.ASSOCIATION.CREATE,
  association,
});

export const associationCreated = (association:Association):AssociationCreatedAction => ({
  type: PROFILE_ACTION_TYPES.ASSOCIATION.CREATED,
  association,
  id: association.id,
});

export const updateAssociation = (association:FormData):UpdateAssociationAction => ({
  type: PROFILE_ACTION_TYPES.ASSOCIATION.UPDATE,
  association,
  id: association.get('id') as string|number,
});

export const associationUpdated = (association:Association):AssociationUpdatedAction => ({
  type: PROFILE_ACTION_TYPES.ASSOCIATION.UPDATED,
  association,
  id: association.id,
});

export const destroyAssociation = (association:FormData):DestroyAssociationAction => ({
  type: PROFILE_ACTION_TYPES.ASSOCIATION.DESTROY,
  id: association.get('id') as string|number,
});

export const associationDestroyed = (id:number|string):AssociationDestroyedAction => ({
  type: PROFILE_ACTION_TYPES.ASSOCIATION.DESTROYED,
  id,
});
