interface ProcurementActionTypes {
  PURCHASES: {
    FETCH: 'disembark/purchases/fetch_purchases',
    FETCHED: 'disembark/purchases/purchases_fetched',
  },
  PURCHASE: {
    FETCH: 'disembark/purchase/fetch_purchase',
    FETCHED: 'disembark/purchase/purchase_fetched',
    FETCH_BY_RECORD_INSTANCE: 'disembark/purchase/fetch_purchase_by_record_instance',
    FETCHED_BY_RECORD_INSTANCE: 'disembark/purchase/purchase_fetched_by_record_instance',
    CREATE: 'disembark/purchase/create_purchase',
    CREATED: 'disembark/purchase/purchase_created',
    UPDATE: 'disembark/purchase/update_purchase',
    UPDATED: 'disembark/purchase/purchase_updated',
    DESTROY: 'disembark/purchase/destroy_purchase',
    DESTROYED: 'disembark/purchase/purchase_destroyed'
  },
  SUPPLIERS: {
    FETCH: 'disembark/suppliers/fetch_suppliers',
    FETCHED: 'disembark/suppliers/suppliers_fetched',
    SEARCH: 'disembark/suppliers/search_suppliers',
    SEARCHED: 'disembark/suppliers/suppliers_searched',
    CLEAR_SEARCH: 'disembark/suppliers/clear_search',
  },
  SUPPLIER: {
    FETCH: 'disembark/supplier/fetch_supplier',
    FETCHED: 'disembark/supplier/supplier_fetched',
    CREATE: 'disembark/supplier/create_supplier',
    CREATED: 'disembark/supplier/supplier_created',
    UPDATE: 'disembark/supplier/update_supplier',
    UPDATED: 'disembark/supplier/supplier_updated',
    DESTROY: 'disembark/supplier/destroy_supplier',
    DESTROYED: 'disembark/supplier/supplier_destroyed'
  },
  CONTACTS: {
    FETCH: 'disembark/contacts/fetch_contacts',
    FETCHED: 'disembark/contacts/contacts_fetched'
  },
  CONTACT: {
    FETCH: 'disembark/contact/fetch_contact',
    FETCHED: 'disembark/contact/contact_fetched',
    CREATE: 'disembark/contact/create_contact',
    CREATED: 'disembark/contact/contact_created',
    UPDATE: 'disembark/contact/update_contact',
    UPDATED: 'disembark/contact/contact_updated',
    DESTROY: 'disembark/contact/destroy_contact',
    DESTROYED: 'disembark/contact/contact_destroyed'
  },
  CONTRACTS: {
    FETCH: 'disembark/contracts/fetch_contracts',
    FETCHED: 'disembark/contracts/contracts_fetched'
  },
  CONTRACT: {
    FETCH: 'disembark/contract/fetch_contract',
    FETCHED: 'disembark/contract/contract_fetched',
    CREATE: 'disembark/contract/create_contract',
    CREATED: 'disembark/contract/contract_created',
    UPDATE: 'disembark/contract/update_contract',
    UPDATED: 'disembark/contract/contract_updated',
    DESTROY: 'disembark/contract/destroy_contract',
    DESTROYED: 'disembark/contract/contract_destroyed'
  },
  FUNDS: {
    FETCH: 'disembark/funds/fetch_funds',
    FETCHED: 'disembark/funds/funds_fetched'
  },
  FUND: {
    FETCH: 'disembark/fund/fetch_fund',
    FETCHED: 'disembark/fund/fund_fetched',
    CREATE: 'disembark/fund/create_fund',
    CREATED: 'disembark/fund/fund_created',
    UPDATE: 'disembark/fund/update_fund',
    UPDATED: 'disembark/fund/fund_updated',
    DESTROY: 'disembark/fund/destroy_fund',
    DESTROYED: 'disembark/fund/fund_destroyed'
  },
  BUDGETS: {
    FETCH: 'disembark/budgets/fetch_budgets',
    FETCHED: 'disembark/budgets/budgets_fetched'
  },
  BUDGET: {
    FETCH: 'disembark/budget/fetch_budget',
    FETCHED: 'disembark/budget/budget_fetched',
    CREATE: 'disembark/budget/create_budget',
    CREATED: 'disembark/budget/budget_created',
    UPDATE: 'disembark/budget/update_budget',
    UPDATED: 'disembark/budget/budget_updated',
    DESTROY: 'disembark/budget/destroy_budget',
    DESTROYED: 'disembark/budget/budget_destroyed'
  },
  INVOICES: {
    FETCH: 'disembark/invoices/fetch_invoices',
    FETCHED: 'disembark/invoices/invoices_fetched'
  },
  INVOICE: {
    FETCH: 'disembark/invoice/fetch_invoice',
    FETCHED: 'disembark/invoice/invoice_fetched',
    CREATE: 'disembark/invoice/create_invoice',
    CREATED: 'disembark/invoice/invoice_created',
    UPDATE: 'disembark/invoice/update_invoice',
    UPDATED: 'disembark/invoice/invoice_updated',
    DESTROY: 'disembark/invoice/destroy_invoice',
    DESTROYED: 'disembark/invoice/invoice_destroyed'
  },
  LINE_ITEMS: {
    FETCH: 'disembark/line_items/fetch_line_items',
    FETCHED: 'disembark/line_items/line_items_fetched'
  },
  LINE_ITEM: {
    FETCH: 'disembark/line_item/fetch_line_item',
    FETCHED: 'disembark/line_item/line_item_fetched',
    CREATE: 'disembark/line_item/create_line_item',
    CREATED: 'disembark/line_item/line_item_created',
    UPDATE: 'disembark/line_item/update_line_item',
    UPDATED: 'disembark/line_item/line_item_updated',
    DESTROY: 'disembark/line_item/destroy_line_item',
    DESTROYED: 'disembark/line_item/line_item_destroyed'
  },
}

export const PROCUREMENT_ACTION_TYPES:ProcurementActionTypes = {
  PURCHASES: {
    FETCH: 'disembark/purchases/fetch_purchases',
    FETCHED: 'disembark/purchases/purchases_fetched',
  },
  PURCHASE: {
    FETCH: 'disembark/purchase/fetch_purchase',
    FETCHED: 'disembark/purchase/purchase_fetched',
    FETCH_BY_RECORD_INSTANCE: 'disembark/purchase/fetch_purchase_by_record_instance',
    FETCHED_BY_RECORD_INSTANCE: 'disembark/purchase/purchase_fetched_by_record_instance',
    CREATE: 'disembark/purchase/create_purchase',
    CREATED: 'disembark/purchase/purchase_created',
    UPDATE: 'disembark/purchase/update_purchase',
    UPDATED: 'disembark/purchase/purchase_updated',
    DESTROY: 'disembark/purchase/destroy_purchase',
    DESTROYED: 'disembark/purchase/purchase_destroyed',
  },
  SUPPLIERS: {
    FETCH: 'disembark/suppliers/fetch_suppliers',
    FETCHED: 'disembark/suppliers/suppliers_fetched',
    SEARCH: 'disembark/suppliers/search_suppliers',
    SEARCHED: 'disembark/suppliers/suppliers_searched',
    CLEAR_SEARCH: 'disembark/suppliers/clear_search',
  },
  SUPPLIER: {
    FETCH: 'disembark/supplier/fetch_supplier',
    FETCHED: 'disembark/supplier/supplier_fetched',
    CREATE: 'disembark/supplier/create_supplier',
    CREATED: 'disembark/supplier/supplier_created',
    UPDATE: 'disembark/supplier/update_supplier',
    UPDATED: 'disembark/supplier/supplier_updated',
    DESTROY: 'disembark/supplier/destroy_supplier',
    DESTROYED: 'disembark/supplier/supplier_destroyed',
  },
  CONTACTS: {
    FETCH: 'disembark/contacts/fetch_contacts',
    FETCHED: 'disembark/contacts/contacts_fetched',
  },
  CONTACT: {
    FETCH: 'disembark/contact/fetch_contact',
    FETCHED: 'disembark/contact/contact_fetched',
    CREATE: 'disembark/contact/create_contact',
    CREATED: 'disembark/contact/contact_created',
    UPDATE: 'disembark/contact/update_contact',
    UPDATED: 'disembark/contact/contact_updated',
    DESTROY: 'disembark/contact/destroy_contact',
    DESTROYED: 'disembark/contact/contact_destroyed',
  },
  CONTRACTS: {
    FETCH: 'disembark/contracts/fetch_contracts',
    FETCHED: 'disembark/contracts/contracts_fetched',
  },
  CONTRACT: {
    FETCH: 'disembark/contract/fetch_contract',
    FETCHED: 'disembark/contract/contract_fetched',
    CREATE: 'disembark/contract/create_contract',
    CREATED: 'disembark/contract/contract_created',
    UPDATE: 'disembark/contract/update_contract',
    UPDATED: 'disembark/contract/contract_updated',
    DESTROY: 'disembark/contract/destroy_contract',
    DESTROYED: 'disembark/contract/contract_destroyed',
  },
  FUNDS: {
    FETCH: 'disembark/funds/fetch_funds',
    FETCHED: 'disembark/funds/funds_fetched',
  },
  FUND: {
    FETCH: 'disembark/fund/fetch_fund',
    FETCHED: 'disembark/fund/fund_fetched',
    CREATE: 'disembark/fund/create_fund',
    CREATED: 'disembark/fund/fund_created',
    UPDATE: 'disembark/fund/update_fund',
    UPDATED: 'disembark/fund/fund_updated',
    DESTROY: 'disembark/fund/destroy_fund',
    DESTROYED: 'disembark/fund/fund_destroyed',
  },
  BUDGETS: {
    FETCH: 'disembark/budgets/fetch_budgets',
    FETCHED: 'disembark/budgets/budgets_fetched',
  },
  BUDGET: {
    FETCH: 'disembark/budget/fetch_budget',
    FETCHED: 'disembark/budget/budget_fetched',
    CREATE: 'disembark/budget/create_budget',
    CREATED: 'disembark/budget/budget_created',
    UPDATE: 'disembark/budget/update_budget',
    UPDATED: 'disembark/budget/budget_updated',
    DESTROY: 'disembark/budget/destroy_budget',
    DESTROYED: 'disembark/budget/budget_destroyed',
  },
  INVOICES: {
    FETCH: 'disembark/invoices/fetch_invoices',
    FETCHED: 'disembark/invoices/invoices_fetched',
  },
  INVOICE: {
    FETCH: 'disembark/invoice/fetch_invoice',
    FETCHED: 'disembark/invoice/invoice_fetched',
    CREATE: 'disembark/invoice/create_invoice',
    CREATED: 'disembark/invoice/invoice_created',
    UPDATE: 'disembark/invoice/update_invoice',
    UPDATED: 'disembark/invoice/invoice_updated',
    DESTROY: 'disembark/invoice/destroy_invoice',
    DESTROYED: 'disembark/invoice/invoice_destroyed',
  },
  LINE_ITEMS: {
    FETCH: 'disembark/line_items/fetch_line_items',
    FETCHED: 'disembark/line_items/line_items_fetched',
  },
  LINE_ITEM: {
    FETCH: 'disembark/line_item/fetch_line_item',
    FETCHED: 'disembark/line_item/line_item_fetched',
    CREATE: 'disembark/line_item/create_line_item',
    CREATED: 'disembark/line_item/line_item_created',
    UPDATE: 'disembark/line_item/update_line_item',
    UPDATED: 'disembark/line_item/line_item_updated',
    DESTROY: 'disembark/line_item/destroy_line_item',
    DESTROYED: 'disembark/line_item/line_item_destroyed',
  },
};

export default PROCUREMENT_ACTION_TYPES;
