import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import { Observable } from 'rxjs';
import {
  CreateListAction,
  DestroyListAction,
  DestroyListsAction,
  FetchListAction,
  FetchListsAction,
  SearchListsAction,
  UpdateListAction,
} from '@store/catalog/lists/types';
import {
  listCreated, listDestroyed, listFetched, listsDestroyed, listsFetched, listsSearched, listUpdated,
} from '@store/catalog/lists/actions';
import { Action } from 'redux';
import CONFIG from '@config/environments/base';
import { RootStateOrAny } from 'react-redux';

const fetchListsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.LISTS.FETCH),
  switchMap((action:FetchListsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/lists/`,
    method: 'GET',
    success: listsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchListEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.LIST.FETCH),
  switchMap((action:FetchListAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/lists/${action.id}/`,
    method: 'GET',
    success: listFetched,
    auth: true,
    state$,
    action,
  })),
);

const createListEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.LIST.CREATE),
  switchMap((action:CreateListAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/lists/`,
    method: 'POST',
    success: listCreated,
    auth: true,
    state$,
    action,
    body: action.list,
  })),
);

const updateListEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.LIST.UPDATE),
  switchMap((action:UpdateListAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/lists/${action.id}/`,
    method: 'PUT',
    success: listUpdated,
    auth: true,
    state$,
    action,
    body: action.list,
  })),
);

const destroyListEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.LIST.DESTROY),
  switchMap((action:DestroyListAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/lists/${action.id}/`,
    method: 'DELETE',
    success: listDestroyed,
    auth: true,
    state$,
    action,
  })),
);

const destroyListsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.LISTS.DESTROY),
  switchMap((action:DestroyListsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/lists/`,
    method: 'DELETE',
    success: listsDestroyed,
    auth: true,
    state$,
    action,
    body: action.lists,
  })),
);

const searchListsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.LISTS.SEARCH),
  switchMap((action:SearchListsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/lists/`,
    method: 'POST',
    success: listsSearched,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchListsEpic,
  destroyListsEpic,
  fetchListEpic,
  createListEpic,
  updateListEpic,
  destroyListEpic,
  searchListsEpic,
];
