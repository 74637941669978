import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { PROFILE_ACTION_TYPES } from '@store/profile/actionTypes';
import {
  FetchAssociationsAction,
  FetchAssociationAction,
  CreateAssociationAction,
  UpdateAssociationAction,
  DestroyAssociationAction,
} from '@store/profile/associations/types';
import {
  associationsFetched,
  associationFetched,
  associationCreated,
  associationUpdated,
  associationDestroyed,
} from '@store/profile/associations/actions';
import { Observable } from 'rxjs';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchAssociationsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROFILE_ACTION_TYPES.ASSOCIATIONS.FETCH),
  switchMap((action:FetchAssociationsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/me/associations/`,
    method: 'PUT',
    success: associationsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchAssociationEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROFILE_ACTION_TYPES.ASSOCIATION.FETCH),
  switchMap((action:FetchAssociationAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/me/associations/${action.id}/`,
    method: 'GET',
    success: associationFetched,
    auth: true,
    state$,
    action,
  })),
);

const createAssociationEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROFILE_ACTION_TYPES.ASSOCIATION.CREATE),
  switchMap((action:CreateAssociationAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/me/associations/`,
    method: 'POST',
    success: associationCreated,
    auth: true,
    state$,
    action,
    body: action.association,
  })),
);

const updateAssociationEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROFILE_ACTION_TYPES.ASSOCIATION.UPDATE),
  switchMap((action:UpdateAssociationAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/me/associations/${action.id}/`,
    method: 'PUT',
    success: associationUpdated,
    auth: true,
    state$,
    action,
    body: action.association,
  })),
);

const destroyAssociationEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROFILE_ACTION_TYPES.ASSOCIATION.DESTROY),
  switchMap((action:DestroyAssociationAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/me/associations/${action.id}/`,
    method: 'DELETE',
    success: associationDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchAssociationsEpic,
  fetchAssociationEpic,
  createAssociationEpic,
  updateAssociationEpic,
  destroyAssociationEpic,
];
