import { RootStateOrAny } from 'react-redux';
import { Action } from 'redux';
import { ObservableInput, of } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { reportApiUnauthorized, reportGenericApiError } from './actions';

const retrieveError = (error:any) => {
  console.warn('---ERROR----');
  console.warn(error);
  if (error.message === null) {
    return ['Unable to connect to server.'];
  }
  if (error.message !== undefined) {
    return error.message;
  }
  return [error.message];
};

export const handleAPIErrors = (action:any, response:AjaxResponse):ObservableInput<any> => {
  const messages = retrieveError(response);
  switch (response.status) {
    case 401:
      return of(reportApiUnauthorized(action.type, messages));
    default:
      return of(reportGenericApiError(action.type, messages));
  }
};

export const fetchAuthHeaders = (state$:RootStateOrAny) => ({
  Authorization: `Token token="${state$.value.auth.apiKey.token}"`,
});

interface AjaxRequestOptions {
  url: string
  method: 'POST' | 'PUT' | 'DELETE' | 'GET'
  auth: boolean
  success: any
  state$: RootStateOrAny
  action: Action
  body?: object
}

export const handleAJAXRequest = (options:AjaxRequestOptions) => ajax({
  url: options.url,
  method: options.method,
  headers: options.auth && fetchAuthHeaders(options.state$),
  body: options.body,
})
  .pipe(
    map(
      (response:AjaxResponse) => options.success(response.response),
    ),
    catchError(
      (error):ObservableInput<Action> => handleAPIErrors(options.action, error),
    ),
  );
