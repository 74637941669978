import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  CreateSupplierAction,
  DestroySupplierAction,
  FetchSupplierAction,
  FetchSuppliersAction,
  FetchSuppliersOptions,
  SearchSuppliersAction,
  Supplier,
  SupplierCreatedAction,
  SupplierDestroyedAction,
  SupplierFetchedAction,
  SuppliersFetchedAction,
  SupplierUpdatedAction,
  UpdateSupplierAction,
} from '@store/procurement/suppliers/types';

export const fetchSuppliers = (options:FetchSuppliersOptions = undefined):FetchSuppliersAction => ({
  type: PROCUREMENT_ACTION_TYPES.SUPPLIERS.FETCH,
  options,
});

export const suppliersFetched = (response:Supplier[]):SuppliersFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.SUPPLIERS.FETCHED,
  suppliers: response,
});

export const fetchSupplier = (id:number|string):FetchSupplierAction => ({
  type: PROCUREMENT_ACTION_TYPES.SUPPLIER.FETCH,
  id,
});

export const supplierFetched = (supplier:Supplier):SupplierFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.SUPPLIER.FETCHED,
  supplier,
});

export const createSupplier = (supplier:FormData):CreateSupplierAction => ({
  type: PROCUREMENT_ACTION_TYPES.SUPPLIER.CREATE,
  supplier,
});

export const supplierCreated = (supplier:Supplier):SupplierCreatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.SUPPLIER.CREATED,
  supplier,
  id: supplier.id,
});

export const updateSupplier = (supplier:FormData):UpdateSupplierAction => ({
  type: PROCUREMENT_ACTION_TYPES.SUPPLIER.UPDATE,
  supplier,
  id: supplier.get('id') as string|number,
});

export const supplierUpdated = (supplier:Supplier):SupplierUpdatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.SUPPLIER.UPDATED,
  supplier,
  id: supplier.id,
});

export const destroySupplier = (supplier:FormData):DestroySupplierAction => ({
  type: PROCUREMENT_ACTION_TYPES.SUPPLIER.DESTROY,
  id: supplier.get('id') as string|number,
});

export const supplierDestroyed = (id:number|string):SupplierDestroyedAction => ({
  type: PROCUREMENT_ACTION_TYPES.SUPPLIER.DESTROYED,
  id,
});

export const searchSuppliers = (term:string):SearchSuppliersAction => ({
  type: PROCUREMENT_ACTION_TYPES.SUPPLIERS.SEARCH,
  term,
});

export const suppliersSearched = (results:Supplier[]) => ({
  type: PROCUREMENT_ACTION_TYPES.SUPPLIERS.SEARCHED,
  results,
});

export const clearSupplierSearch = () => ({
  type: PROCUREMENT_ACTION_TYPES.SUPPLIERS.CLEAR_SEARCH,
});
