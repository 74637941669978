import { PROFILE_ACTION_TYPES } from '@store/profile/actionTypes';
import {
  CreateFamilyMemberAction,
  DestroyFamilyMemberAction,
  FetchFamilyMemberAction,
  FetchFamilyMembersAction,
  FamilyMember,
  FamilyMemberCreatedAction,
  FamilyMemberDestroyedAction,
  FamilyMemberFetchedAction,
  FamilyMembersFetchedAction,
  FamilyMemberUpdatedAction,
  UpdateFamilyMemberAction,
  FetchFamilyMembersOptions,
} from '@store/profile/family/types';

export const fetchFamilyMembers = (
  options:FetchFamilyMembersOptions = undefined,
):FetchFamilyMembersAction => ({
  type: PROFILE_ACTION_TYPES.FAMILY_MEMBERS.FETCH,
  options,
});

export const familyMembersFetched = (
  response:FamilyMember[],
):FamilyMembersFetchedAction => ({
  type: PROFILE_ACTION_TYPES.FAMILY_MEMBERS.FETCHED,
  familyMembers: response,
});

export const fetchFamilyMember = (id:number|string):FetchFamilyMemberAction => ({
  type: PROFILE_ACTION_TYPES.FAMILY_MEMBER.FETCH,
  id,
});

export const familyMemberFetched = (familyMember:FamilyMember):FamilyMemberFetchedAction => ({
  type: PROFILE_ACTION_TYPES.FAMILY_MEMBER.FETCHED,
  familyMember,
});

export const createFamilyMember = (familyMember:FormData):CreateFamilyMemberAction => ({
  type: PROFILE_ACTION_TYPES.FAMILY_MEMBER.CREATE,
  familyMember,
});

export const familyMemberCreated = (familyMember:FamilyMember):FamilyMemberCreatedAction => ({
  type: PROFILE_ACTION_TYPES.FAMILY_MEMBER.CREATED,
  familyMember,
  id: familyMember.id,
});

export const updateFamilyMember = (familyMember:FormData):UpdateFamilyMemberAction => ({
  type: PROFILE_ACTION_TYPES.FAMILY_MEMBER.UPDATE,
  familyMember,
  id: familyMember.get('id') as string|number,
});

export const familyMemberUpdated = (familyMember:FamilyMember):FamilyMemberUpdatedAction => ({
  type: PROFILE_ACTION_TYPES.FAMILY_MEMBER.UPDATED,
  familyMember,
  id: familyMember.id,
});

export const destroyFamilyMember = (familyMember:FormData):DestroyFamilyMemberAction => ({
  type: PROFILE_ACTION_TYPES.FAMILY_MEMBER.DESTROY,
  id: familyMember.get('id') as string|number,
});

export const familyMemberDestroyed = (id:number|string):FamilyMemberDestroyedAction => ({
  type: PROFILE_ACTION_TYPES.FAMILY_MEMBER.DESTROYED,
  id,
});
