const GROUPINGS = {
  PENDING: /check_|load_|fetch_|update_|create_|destroy_|add_|import_|search_/,
  COMPLETED: /_loaded|_fetched|_updated|_created|_destroyed|_added|_imported|_searched|_checked/,
  REQUIRES_ID: /update_|destroy_|_updated|_destroyed/,
  ONEWAY: /clear_/,
  ERROR: /error/,
  APP: /@@/,
};

export default GROUPINGS;
