interface IPATRON_ACTION_TYPES {
  PATRONS: {
    FETCH: 'disembark/patrons/fetch_patrons',
    FETCHED: 'disembark/patrons/patrons_fetched',
    DESTROY: 'disembark/patron/destroy_patrons',
    DESTROYED: 'disembark/patron/patrons_destroyed',
    SEARCH: 'disembark/patrons/search_patrons',
    SEARCHED: 'disembark/patrons/patrons_searched',
    CLEAR_SEARCH: 'disembark/patrons/clear_search'
  },
  PATRON: {
    FETCH: 'disembark/patron/fetch_patron',
    FETCHED: 'disembark/patron/patron_fetched',
    CREATE: 'disembark/patron/create_patron',
    CREATED: 'disembark/patron/patron_created',
    UPDATE: 'disembark/patron/update_patron',
    UPDATED: 'disembark/patron/patron_updated',
    DESTROY: 'disembark/patron/destroy_patron',
    DESTROYED: 'disembark/patron/patron_destroyed',
    CLEAR: 'disembark/patron/clear_patron'
  },
  PATRON_GROUPS: {
    FETCH: 'disembark/patron_groups/fetch_patron_groups',
    FETCHED: 'disembark/patron_groups/patron_groups_fetched',
    DESTROY: 'disembark/patron_group/destroy_patron_groups',
    DESTROYED: 'disembark/patron_group/patron_groups_destroyed',
    SEARCH: 'disembark/patron_groups/search_patron_groups',
    SEARCHED: 'disembark/patron_groups/patron_groups_searched',
    CLEAR_SEARCH: 'disembark/patron_groups/clear_search',
  },
  PATRON_GROUP: {
    FETCH: 'disembark/patron_group/fetch_patron_group',
    FETCHED: 'disembark/patron_group/patron_group_fetched',
    CREATE: 'disembark/patron_group/create_patron_group',
    CREATED: 'disembark/patron_group/patron_group_created',
    UPDATE: 'disembark/patron_group/update_patron_group',
    UPDATED: 'disembark/patron_group/patron_group_updated',
    DESTROY: 'disembark/patron_group/destroy_patron_group',
    DESTROYED: 'disembark/patron_group/patron_group_destroyed',
    CREATE_MEMBERSHIPS: 'disembark/patron_group/create_patron_group_memberships',
    MEMBERSHIPS_CREATED: 'disembark/patron_group/patron_group_memberships_created',
  }
}

export const PATRON_ACTION_TYPES:IPATRON_ACTION_TYPES = {
  PATRONS: {
    FETCH: 'disembark/patrons/fetch_patrons',
    FETCHED: 'disembark/patrons/patrons_fetched',
    DESTROY: 'disembark/patron/destroy_patrons',
    DESTROYED: 'disembark/patron/patrons_destroyed',
    SEARCH: 'disembark/patrons/search_patrons',
    SEARCHED: 'disembark/patrons/patrons_searched',
    CLEAR_SEARCH: 'disembark/patrons/clear_search',
  },
  PATRON: {
    FETCH: 'disembark/patron/fetch_patron',
    FETCHED: 'disembark/patron/patron_fetched',
    CREATE: 'disembark/patron/create_patron',
    CREATED: 'disembark/patron/patron_created',
    UPDATE: 'disembark/patron/update_patron',
    UPDATED: 'disembark/patron/patron_updated',
    DESTROY: 'disembark/patron/destroy_patron',
    DESTROYED: 'disembark/patron/patron_destroyed',
    CLEAR: 'disembark/patron/clear_patron',
  },
  PATRON_GROUPS: {
    FETCH: 'disembark/patron_groups/fetch_patron_groups',
    FETCHED: 'disembark/patron_groups/patron_groups_fetched',
    DESTROY: 'disembark/patron_group/destroy_patron_groups',
    DESTROYED: 'disembark/patron_group/patron_groups_destroyed',
    SEARCH: 'disembark/patron_groups/search_patron_groups',
    SEARCHED: 'disembark/patron_groups/patron_groups_searched',
    CLEAR_SEARCH: 'disembark/patron_groups/clear_search',
  },
  PATRON_GROUP: {
    FETCH: 'disembark/patron_group/fetch_patron_group',
    FETCHED: 'disembark/patron_group/patron_group_fetched',
    CREATE: 'disembark/patron_group/create_patron_group',
    CREATED: 'disembark/patron_group/patron_group_created',
    UPDATE: 'disembark/patron_group/update_patron_group',
    UPDATED: 'disembark/patron_group/patron_group_updated',
    DESTROY: 'disembark/patron_group/destroy_patron_group',
    DESTROYED: 'disembark/patron_group/patron_group_destroyed',
    CREATE_MEMBERSHIPS: 'disembark/patron_group/create_patron_group_memberships',
    MEMBERSHIPS_CREATED: 'disembark/patron_group/patron_group_memberships_created',
  },
};

export default PATRON_ACTION_TYPES;
