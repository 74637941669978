import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { PROFILE_ACTION_TYPES } from '@store/profile/actionTypes';
import {
  FetchFamilyMembersAction,
  FetchFamilyMemberAction,
  CreateFamilyMemberAction,
  UpdateFamilyMemberAction,
  DestroyFamilyMemberAction,
} from '@store/profile/family/types';
import {
  familyMembersFetched,
  familyMemberFetched,
  familyMemberCreated,
  familyMemberUpdated,
  familyMemberDestroyed,
} from '@store/profile/family/actions';
import { Observable } from 'rxjs';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchFamilyMembersEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROFILE_ACTION_TYPES.FAMILY_MEMBERS.FETCH),
  switchMap((action:FetchFamilyMembersAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}me/family-members/`,
    method: 'GET',
    success: familyMembersFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchFamilyMemberEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROFILE_ACTION_TYPES.FAMILY_MEMBER.FETCH),
  switchMap((action:FetchFamilyMemberAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/me/family-members/${action.id}/`,
    method: 'GET',
    success: familyMemberFetched,
    auth: true,
    state$,
    action,
  })),
);

const createFamilyMemberEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROFILE_ACTION_TYPES.FAMILY_MEMBER.CREATE),
  switchMap((action:CreateFamilyMemberAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/me/family-members/`,
    method: 'POST',
    success: familyMemberCreated,
    auth: true,
    state$,
    action,
    body: action.familyMember,
  })),
);

const updateFamilyMemberEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROFILE_ACTION_TYPES.FAMILY_MEMBER.UPDATE),
  switchMap((action:UpdateFamilyMemberAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/me/family-members/${action.id}/`,
    method: 'PUT',
    success: familyMemberUpdated,
    auth: true,
    state$,
    action,
    body: action.familyMember,
  })),
);

const destroyFamilyMemberEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROFILE_ACTION_TYPES.FAMILY_MEMBER.DESTROY),
  switchMap((action:DestroyFamilyMemberAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.AUTH_URL}/me/family-members/${action.id}`,
    method: 'DELETE',
    success: familyMemberDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchFamilyMembersEpic,
  fetchFamilyMemberEpic,
  createFamilyMemberEpic,
  updateFamilyMemberEpic,
  destroyFamilyMemberEpic,
];
