import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import {
  CreateListAction,
  ListCreatedAction,
  FetchListAction,
  FetchListsAction,
  ListFetchedAction,
  ListsFetchedAction,
  List,
  UpdateListAction,
  ListUpdatedAction,
  DestroyListAction,
  ListDestroyedAction,
  SearchListsAction,
  ListsSearchedAction,
  ClearListSearchResultsAction,
  ClearCurrentListAction,
  FetchListsOptions,
  DestroyListsAction,
  ListsDetroyedAction,
} from '@store/catalog/lists/types';

export function fetchLists(options:FetchListsOptions = undefined):FetchListsAction {
  return {
    type: CATALOG_ACTION_TYPES.LISTS.FETCH,
    options,
  };
}

export function listsFetched(response:List[]):ListsFetchedAction {
  return {
    type: CATALOG_ACTION_TYPES.LISTS.FETCHED,
    lists: response,
  };
}

export function destroyLists(lists:List[]):DestroyListsAction {
  return {
    type: CATALOG_ACTION_TYPES.LISTS.DESTROY,
    lists,
  };
}

export function listsDestroyed():ListsDetroyedAction {
  return {
    type: CATALOG_ACTION_TYPES.LISTS.DESTROYED,
  };
}

export function fetchList(id:number|string):FetchListAction {
  return {
    type: CATALOG_ACTION_TYPES.LIST.FETCH,
    id,
  };
}

export function listFetched(list:List):ListFetchedAction {
  return {
    type: CATALOG_ACTION_TYPES.LIST.FETCHED,
    list,
    id: list.id,
  };
}

export const createList = (list:FormData):CreateListAction => ({
  type: CATALOG_ACTION_TYPES.LIST.CREATE,
  list,
});

export const listCreated = (list:List):ListCreatedAction => ({
  type: CATALOG_ACTION_TYPES.LIST.CREATED,
  list,
  id: list.id,
});

export const updateList = (list:FormData):UpdateListAction => ({
  type: CATALOG_ACTION_TYPES.LIST.UPDATE,
  list,
  id: list.get('id') as string|number,
});

export const listUpdated = (list:List):ListUpdatedAction => ({
  type: CATALOG_ACTION_TYPES.LIST.UPDATED,
  list,
  id: list.id,
});

export const destroyList = (list:FormData):DestroyListAction => ({
  type: CATALOG_ACTION_TYPES.LIST.DESTROY,
  id: list.get('id') as string|number,
});

export const listDestroyed = (id: number|string):ListDestroyedAction => ({
  type: CATALOG_ACTION_TYPES.LIST.DESTROYED,
  id,
});

export const searchLists = (term:string):SearchListsAction => ({
  type: CATALOG_ACTION_TYPES.LISTS.SEARCH,
  term,
});

export const listsSearched = (results:List[]):ListsSearchedAction => ({
  type: CATALOG_ACTION_TYPES.LISTS.SEARCHED,
  results,
});

export const clearListSearchResults = ():ClearListSearchResultsAction => ({
  type: CATALOG_ACTION_TYPES.LISTS.CLEAR_SEARCH,
});

export const clearCurrentList = ():ClearCurrentListAction => ({
  type: CATALOG_ACTION_TYPES.LIST.CLEAR,
});
