export interface CirculationActionTypes {
  LEASES: {
    FETCH: 'disembark/circulation/leases/fetch_leases',
    FETCHED: 'disembark/circulation/leases/leases_fetched',
    DESTROY: 'disembark/circulation/leases/destroy_leases',
    DESTROYED: 'disembark/circulation/leases/leases_destroyed',
    SEARCH: 'disembark/circulation/leases/search_leases',
    SEARCHED: 'disembark/circulation/leases/leases_searched',
    CLEAR: 'disembark/circulation/leases/clear_leases',
    CLEAR_SEARCH: 'disembark/circulation/leases/clear_search'
  },
  LEASE: {
    FETCH: 'disembark/circulation/lease/fetch_lease',
    FETCHED: 'disembark/circulation/lease/lease_fetched',
    CREATE: 'disembark/circulation/lease/create_lease',
    CREATED: 'disembark/circulation/lease/lease_created',
    UPDATE: 'disembark/circulation/lease/update_lease',
    UPDATED: 'disembark/circulation/lease/lease_updated',
    DESTROY: 'disembark/circulation/lease/destroy_lease',
    DESTROYED: 'disembark/circulation/lease/lease_destroyed',
    CLEAR: 'disembark/circulation/lease/clear_lease',
  },
  FEES: {
    FETCH: 'disembark/circulation/fees/fetch_fees',
    FETCHED: 'disembark/circulation/fees/fees_fetched',
  },
  FEE: {
    FETCH: 'disembark/circulation/fee/fetch_fee',
    FETCHED: 'disembark/circulation/fee/fee_fetched',
    CREATE: 'disembark/circulation/fee/create_fee',
    CREATED: 'disembark/circulation/fee/fee_created',
    UPDATE: 'disembark/circulation/fee/update_fee',
    UPDATED: 'disembark/circulation/fee/fee_updated',
    DESTROY: 'disembark/circulation/fee/destroy_fee',
    DESTROYED: 'disembark/circulation/fee/fee_destroyed',
  },
  LOCATIONS: {
    FETCH: 'disembark/circulation/locations/fetch_locations',
    FETCHED: 'disembark/circulation/locations/locations_fetched',
  },
  LOCATION: {
    FETCH: 'disembark/circulation/location/fetch_location',
    FETCHED: 'disembark/circulation/location/location_fetched',
    CREATE: 'disembark/circulation/location/create_location',
    CREATED: 'disembark/circulation/location/location_created',
    UPDATE: 'disembark/circulation/location/update_location',
    UPDATED: 'disembark/circulation/location/location_updated',
    DESTROY: 'disembark/circulation/location/destroy_location',
    DESTROYED: 'disembark/circulation/location/location_destroyed',
  },
  SECTIONS: {
    FETCH: 'disembark/circulation/sections/fetch_sections',
    FETCHED: 'disembark/circulation/sections/sections_fetched',
  },
  SECTION: {
    FETCH: 'disembark/circulation/section/fetch_section',
    FETCHED: 'disembark/circulation/section/section_fetched',
    CREATE: 'disembark/circulation/section/create_section',
    CREATED: 'disembark/circulation/section/section_created',
    UPDATE: 'disembark/circulation/section/update_section',
    UPDATED: 'disembark/circulation/section/section_updated',
    DESTROY: 'disembark/circulation/section/destroy_section',
    DESTROYED: 'disembark/circulation/section/section_destroyed',
  },
  HOLDS: {
    FETCH: 'disembark/circulation/holds/fetch_holds',
    FETCHED: 'disembark/circulation/holds/holds_fetched',
  },
  HOLD: {
    FETCH: 'disembark/circulation/hold/fetch_hold',
    FETCHED: 'disembark/circulation/hold/hold_fetched',
    CREATE: 'disembark/circulation/hold/create_hold',
    CREATED: 'disembark/circulation/hold/hold_created',
    UPDATE: 'disembark/circulation/hold/update_hold',
    UPDATED: 'disembark/circulation/hold/hold_updated',
    DESTROY: 'disembark/circulation/hold/destroy_hold',
    DESTROYED: 'disembark/circulation/hold/hold_destroyed',
  },
  TRANSFERS: {
    FETCH: 'disembark/circulation/transfers/fetch_transfers',
    FETCHED: 'disembark/circulation/transfers/transfers_fetched',
  },
  TRANSFER: {
    FETCH: 'disembark/circulation/transfer/fetch_transfer',
    FETCHED: 'disembark/circulation/transfer/transfer_fetched',
    CREATE: 'disembark/circulation/transfer/create_transfer',
    CREATED: 'disembark/circulation/transfer/transfer_created',
    UPDATE: 'disembark/circulation/transfer/update_transfer',
    UPDATED: 'disembark/circulation/transfer/transfer_updated',
    DESTROY: 'disembark/circulation/transfer/destroy_transfer',
    DESTROYED: 'disembark/circulation/transfer/transfer_destroyed',
  }
}

export const CIRCULATION_ACTION_TYPES:CirculationActionTypes = {
  LEASES: {
    FETCH: 'disembark/circulation/leases/fetch_leases',
    FETCHED: 'disembark/circulation/leases/leases_fetched',
    DESTROY: 'disembark/circulation/leases/destroy_leases',
    DESTROYED: 'disembark/circulation/leases/leases_destroyed',
    SEARCH: 'disembark/circulation/leases/search_leases',
    SEARCHED: 'disembark/circulation/leases/leases_searched',
    CLEAR: 'disembark/circulation/leases/clear_leases',
    CLEAR_SEARCH: 'disembark/circulation/leases/clear_search',
  },
  LEASE: {
    FETCH: 'disembark/circulation/lease/fetch_lease',
    FETCHED: 'disembark/circulation/lease/lease_fetched',
    CREATE: 'disembark/circulation/lease/create_lease',
    CREATED: 'disembark/circulation/lease/lease_created',
    UPDATE: 'disembark/circulation/lease/update_lease',
    UPDATED: 'disembark/circulation/lease/lease_updated',
    DESTROY: 'disembark/circulation/lease/destroy_lease',
    DESTROYED: 'disembark/circulation/lease/lease_destroyed',
    CLEAR: 'disembark/circulation/lease/clear_lease',
  },
  FEES: {
    FETCH: 'disembark/circulation/fees/fetch_fees',
    FETCHED: 'disembark/circulation/fees/fees_fetched',
  },
  FEE: {
    FETCH: 'disembark/circulation/fee/fetch_fee',
    FETCHED: 'disembark/circulation/fee/fee_fetched',
    CREATE: 'disembark/circulation/fee/create_fee',
    CREATED: 'disembark/circulation/fee/fee_created',
    UPDATE: 'disembark/circulation/fee/update_fee',
    UPDATED: 'disembark/circulation/fee/fee_updated',
    DESTROY: 'disembark/circulation/fee/destroy_fee',
    DESTROYED: 'disembark/circulation/fee/fee_destroyed',
  },
  LOCATIONS: {
    FETCH: 'disembark/circulation/locations/fetch_locations',
    FETCHED: 'disembark/circulation/locations/locations_fetched',
  },
  LOCATION: {
    FETCH: 'disembark/circulation/location/fetch_location',
    FETCHED: 'disembark/circulation/location/location_fetched',
    CREATE: 'disembark/circulation/location/create_location',
    CREATED: 'disembark/circulation/location/location_created',
    UPDATE: 'disembark/circulation/location/update_location',
    UPDATED: 'disembark/circulation/location/location_updated',
    DESTROY: 'disembark/circulation/location/destroy_location',
    DESTROYED: 'disembark/circulation/location/location_destroyed',
  },
  SECTIONS: {
    FETCH: 'disembark/circulation/sections/fetch_sections',
    FETCHED: 'disembark/circulation/sections/sections_fetched',
  },
  SECTION: {
    FETCH: 'disembark/circulation/section/fetch_section',
    FETCHED: 'disembark/circulation/section/section_fetched',
    CREATE: 'disembark/circulation/section/create_section',
    CREATED: 'disembark/circulation/section/section_created',
    UPDATE: 'disembark/circulation/section/update_section',
    UPDATED: 'disembark/circulation/section/section_updated',
    DESTROY: 'disembark/circulation/section/destroy_section',
    DESTROYED: 'disembark/circulation/section/section_destroyed',
  },
  HOLDS: {
    FETCH: 'disembark/circulation/holds/fetch_holds',
    FETCHED: 'disembark/circulation/holds/holds_fetched',
  },
  HOLD: {
    FETCH: 'disembark/circulation/hold/fetch_hold',
    FETCHED: 'disembark/circulation/hold/hold_fetched',
    CREATE: 'disembark/circulation/hold/create_hold',
    CREATED: 'disembark/circulation/hold/hold_created',
    UPDATE: 'disembark/circulation/hold/update_hold',
    UPDATED: 'disembark/circulation/hold/hold_updated',
    DESTROY: 'disembark/circulation/hold/destroy_hold',
    DESTROYED: 'disembark/circulation/hold/hold_destroyed',
  },
  TRANSFERS: {
    FETCH: 'disembark/circulation/transfers/fetch_transfers',
    FETCHED: 'disembark/circulation/transfers/transfers_fetched',
  },
  TRANSFER: {
    FETCH: 'disembark/circulation/transfer/fetch_transfer',
    FETCHED: 'disembark/circulation/transfer/transfer_fetched',
    CREATE: 'disembark/circulation/transfer/create_transfer',
    CREATED: 'disembark/circulation/transfer/transfer_created',
    UPDATE: 'disembark/circulation/transfer/update_transfer',
    UPDATED: 'disembark/circulation/transfer/transfer_updated',
    DESTROY: 'disembark/circulation/transfer/destroy_transfer',
    DESTROYED: 'disembark/circulation/transfer/transfer_destroyed',
  },
};
