import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  CreatePurchaseAction,
  DestroyPurchaseAction,
  FetchPurchaseAction,
  FetchPurchaseByRecordInstanceAction,
  FetchPurchasesAction,
  FetchPurchasesOptions,
  Purchase,
  PurchaseCreatedAction,
  PurchaseDestroyedAction,
  PurchaseFetchedAction,
  PurchaseFetchedByRecordInstanceAction,
  PurchasesFetchedAction,
  PurchaseUpdatedAction,
  UpdatePurchaseAction,
} from '@store/procurement/purchases/types';

export const fetchPurchases = (options:FetchPurchasesOptions = undefined):FetchPurchasesAction => ({
  type: PROCUREMENT_ACTION_TYPES.PURCHASES.FETCH,
  options,
});

export const purchasesFetched = (response:Purchase[]):PurchasesFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.PURCHASES.FETCHED,
  purchases: response,
});

export const fetchPurchase = (id:number|string):FetchPurchaseAction => ({
  type: PROCUREMENT_ACTION_TYPES.PURCHASE.FETCH,
  id,
});

export const purchaseFetched = (purchase:Purchase):PurchaseFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.PURCHASE.FETCHED,
  purchase,
});

export const fetchPurchaseByRecordInstance = (
  recordInstanceId:number|string,
):FetchPurchaseByRecordInstanceAction => ({
  type: PROCUREMENT_ACTION_TYPES.PURCHASE.FETCH_BY_RECORD_INSTANCE,
  recordInstanceId,
});

export const purchaseFetchedByRecordInstance = (
  purchase:Purchase,
):PurchaseFetchedByRecordInstanceAction => ({
  type: PROCUREMENT_ACTION_TYPES.PURCHASE.FETCHED_BY_RECORD_INSTANCE,
  purchase,
});

export const createPurchase = (purchase:FormData):CreatePurchaseAction => ({
  type: PROCUREMENT_ACTION_TYPES.PURCHASE.CREATE,
  purchase,
});

export const purchaseCreated = (purchase:Purchase):PurchaseCreatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.PURCHASE.CREATED,
  purchase,
  id: purchase.id,
});

export const updatePurchase = (purchase:FormData):UpdatePurchaseAction => ({
  type: PROCUREMENT_ACTION_TYPES.PURCHASE.UPDATE,
  purchase,
  id: purchase.get('id') as string|number,
});

export const purchaseUpdated = (purchase:Purchase):PurchaseUpdatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.PURCHASE.UPDATED,
  purchase,
  id: purchase.id,
});

export const destroyPurchase = (purchase:FormData):DestroyPurchaseAction => ({
  type: PROCUREMENT_ACTION_TYPES.PURCHASE.DESTROY,
  id: purchase.get('id') as string|number,
});

export const purchaseDestroyed = (id:number|string):PurchaseDestroyedAction => ({
  type: PROCUREMENT_ACTION_TYPES.PURCHASE.DESTROYED,
  id,
});
