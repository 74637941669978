import { PaginatedAPIResponse } from '@store/application/types';
// import { PROFILE_ACTION_TYPES } from '@store/profile/actionTypes';
import { ProfileFetchedAction } from '@store/profile/profile/types';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import { Budget, BudgetAction } from '@store/procurement/budgets/types';
import { Fund, FundAction } from '@store/procurement/funds/types';
import { Invoice, InvoiceAction } from '@store/procurement/invoices/types';
import { LineItem, LineItemAction } from '@store/procurement/lineItems/types';
import { Purchase, PurchaseAction } from '@store/procurement/purchases/types';
import { Supplier, SupplierAction } from '@store/procurement/suppliers/types';
import { Contact, ContactAction } from './contacts/types';
import { Contract, ContractAction } from './contracts/types';

interface SupplierState extends PaginatedAPIResponse {
  searchResults: Supplier[]
  suppliers: Supplier[]
  currentSupplier:Supplier
  loading: boolean
}

const initialSupplierState:SupplierState = {
  searchResults: [],
  suppliers: [],
  currentSupplier: undefined,
  loading: false,
};

export function suppliers(
  state:SupplierState = initialSupplierState,
  action:SupplierAction = undefined,
) {
  switch (action.type) {
    case PROCUREMENT_ACTION_TYPES.SUPPLIERS.FETCH:
      return {
        ...state,
        suppliers: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case PROCUREMENT_ACTION_TYPES.SUPPLIERS.FETCHED:
      return {
        ...state,
        suppliers: action.suppliers,
        loading: false,
      };
    case PROCUREMENT_ACTION_TYPES.SUPPLIERS.SEARCH:
    case PROCUREMENT_ACTION_TYPES.SUPPLIERS.CLEAR_SEARCH:
      return { ...state, searchResults: [] };
    case PROCUREMENT_ACTION_TYPES.SUPPLIERS.SEARCHED:
      return { ...state, searchResults: action.results };
    case PROCUREMENT_ACTION_TYPES.SUPPLIER.FETCHED:
    case PROCUREMENT_ACTION_TYPES.SUPPLIER.UPDATED:
      return { ...state, currentSupplier: action.supplier };
    case PROCUREMENT_ACTION_TYPES.SUPPLIER.CREATED:
      return { ...state, suppliers: [...state.suppliers, action.supplier] };
    case PROCUREMENT_ACTION_TYPES.SUPPLIER.DESTROYED:
    case PROCUREMENT_ACTION_TYPES.SUPPLIER.UPDATE:
    case PROCUREMENT_ACTION_TYPES.SUPPLIER.CREATE:
      return { ...state, currentSupplier: undefined };
    default:
      return state;
  }
}

interface PurchasesState extends PaginatedAPIResponse {
  searchResults: Purchase[]
  purchases: Purchase[]
  currentPurchase:Purchase
  loading: boolean
}

const initialPurchasesState:PurchasesState = {
  searchResults: [],
  purchases: [],
  currentPurchase: undefined,
  loading: false,
};

export function purchases(
  state:PurchasesState = initialPurchasesState,
  action:PurchaseAction = undefined,
) {
  switch (action.type) {
    case PROCUREMENT_ACTION_TYPES.PURCHASES.FETCH:
      return {
        ...state,
        purchases: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case PROCUREMENT_ACTION_TYPES.PURCHASES.FETCHED:
      return {
        ...state,
        purchases: action.purchases,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case PROCUREMENT_ACTION_TYPES.PURCHASE.FETCHED_BY_RECORD_INSTANCE:
    case PROCUREMENT_ACTION_TYPES.PURCHASE.FETCHED:
    case PROCUREMENT_ACTION_TYPES.PURCHASE.UPDATED:
      return { ...state, currentPurchase: action.purchase };
    case PROCUREMENT_ACTION_TYPES.PURCHASE.CREATED:
      return { ...state, purchases: [...state.purchases, action.purchase] };
    case PROCUREMENT_ACTION_TYPES.PURCHASE.DESTROYED:
    case PROCUREMENT_ACTION_TYPES.PURCHASE.UPDATE:
    case PROCUREMENT_ACTION_TYPES.PURCHASE.CREATE:
      return { ...state, currentPurchase: undefined };
    default:
      return state;
  }
}

interface InvoicesState extends PaginatedAPIResponse {
  searchResults: Invoice[]
  invoices: Invoice[]
  currentInvoice:Invoice
  loading: boolean
}

const initialInvoicesState:InvoicesState = {
  searchResults: [],
  invoices: [],
  currentInvoice: undefined,
  loading: false,
};

export function invoices(
  state:InvoicesState = initialInvoicesState,
  action:InvoiceAction = undefined,
) {
  switch (action.type) {
    case PROCUREMENT_ACTION_TYPES.INVOICES.FETCH:
      return {
        ...state,
        invoices: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case PROCUREMENT_ACTION_TYPES.INVOICES.FETCHED:
      return {
        ...state,
        invoices: action.invoices,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case PROCUREMENT_ACTION_TYPES.INVOICE.FETCHED:
    case PROCUREMENT_ACTION_TYPES.INVOICE.UPDATED:
      return { ...state, currentInvoice: action.invoice };
    case PROCUREMENT_ACTION_TYPES.INVOICE.CREATED:
      return { ...state, invoices: [...state.invoices, action.invoice] };
    case PROCUREMENT_ACTION_TYPES.INVOICE.DESTROYED:
    case PROCUREMENT_ACTION_TYPES.INVOICE.UPDATE:
    case PROCUREMENT_ACTION_TYPES.INVOICE.CREATE:
      return { ...state, currentInvoice: undefined };
    default:
      return state;
  }
}

interface LineItemsState extends PaginatedAPIResponse {
  searchResults: LineItem[]
  lineItems: LineItem[]
  currentLineItem:LineItem
  loading: boolean
}

const initialLineItemsState:LineItemsState = {
  searchResults: [],
  lineItems: [],
  currentLineItem: undefined,
  loading: false,
};

export function lineItems(
  state:LineItemsState = initialLineItemsState,
  action:LineItemAction = undefined,
) {
  switch (action.type) {
    case PROCUREMENT_ACTION_TYPES.LINE_ITEMS.FETCH:
      return {
        ...state,
        lineItems: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case PROCUREMENT_ACTION_TYPES.LINE_ITEMS.FETCHED:
      return {
        ...state,
        lineItems: action.lineItems,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case PROCUREMENT_ACTION_TYPES.LINE_ITEM.FETCHED:
    case PROCUREMENT_ACTION_TYPES.LINE_ITEM.UPDATED:
      return { ...state, currentLineItem: action.lineItem };
    case PROCUREMENT_ACTION_TYPES.LINE_ITEM.CREATED:
      return { ...state, lineItems: [...state.lineItems, action.lineItem] };
    case PROCUREMENT_ACTION_TYPES.LINE_ITEM.DESTROYED:
    case PROCUREMENT_ACTION_TYPES.LINE_ITEM.UPDATE:
    case PROCUREMENT_ACTION_TYPES.LINE_ITEM.CREATE:
      return { ...state, currentLineItem: undefined };
    default:
      return state;
  }
}

interface FundsState extends PaginatedAPIResponse {
  searchResults: Fund[]
  funds: Fund[]
  currentFund:Fund
  loading: boolean
}

const initialFundsState:FundsState = {
  searchResults: [],
  funds: [],
  currentFund: undefined,
  loading: false,
};

export function funds(state:FundsState = initialFundsState, action:FundAction = undefined) {
  switch (action.type) {
    case PROCUREMENT_ACTION_TYPES.FUNDS.FETCH:
      return {
        ...state,
        funds: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case PROCUREMENT_ACTION_TYPES.FUNDS.FETCHED:
      return {
        ...state,
        funds: action.funds,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case PROCUREMENT_ACTION_TYPES.FUND.FETCHED:
    case PROCUREMENT_ACTION_TYPES.FUND.UPDATED:
      return { ...state, currentFund: action.fund };
    case PROCUREMENT_ACTION_TYPES.FUND.CREATED:
      return { ...state, funds: [...state.funds, action.fund] };
    case PROCUREMENT_ACTION_TYPES.FUND.DESTROYED:
    case PROCUREMENT_ACTION_TYPES.FUND.UPDATE:
    case PROCUREMENT_ACTION_TYPES.FUND.CREATE:
      return { ...state, currentFund: {} };
    default:
      return state;
  }
}

interface BudgetsState extends PaginatedAPIResponse {
  searchResults: Budget[]
  budgets: Budget[]
  currentBudget:Budget
  loading: boolean
}

const initialBudgetsState:BudgetsState = {
  searchResults: [],
  budgets: [],
  currentBudget: undefined,
  loading: false,
};

type BudgetReducerActions = | BudgetAction | ProfileFetchedAction;

export function budgets(
  state:BudgetsState = initialBudgetsState,
  action:BudgetReducerActions = undefined,
) {
  switch (action.type) {
    case PROCUREMENT_ACTION_TYPES.BUDGETS.FETCH:
      return {
        ...state,
        budgets: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case PROCUREMENT_ACTION_TYPES.BUDGETS.FETCHED:
      return {
        ...state,
        budgets: action.budgets,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case PROCUREMENT_ACTION_TYPES.BUDGET.FETCHED:
    case PROCUREMENT_ACTION_TYPES.BUDGET.UPDATED:
      return { ...state, currentBudget: action.budget };
    // case PROFILE_ACTION_TYPES.PROFILE.FETCHED:
    //   return { ...state, currentBudget: action.response.defaultOrganization.defaultBudget };
    case PROCUREMENT_ACTION_TYPES.BUDGET.CREATED:
      return { ...state, budgets: [...state.budgets, action.budget] };
    case PROCUREMENT_ACTION_TYPES.BUDGET.DESTROYED:
    case PROCUREMENT_ACTION_TYPES.BUDGET.UPDATE:
    case PROCUREMENT_ACTION_TYPES.BUDGET.CREATE:
      return { ...state, currentBudget: undefined };
    default:
      return state;
  }
}

interface ContactsState extends PaginatedAPIResponse {
  searchResults: Contact[]
  contacts: Contact[]
  currentContact:Contact
  loading: boolean
}

const initialContactsState:ContactsState = {
  searchResults: [],
  contacts: [],
  currentContact: undefined,
  loading: false,
};

type ContactReducerActions = | ContactAction | ProfileFetchedAction;

export function contacts(
  state:ContactsState = initialContactsState,
  action:ContactReducerActions = undefined,
) {
  switch (action.type) {
    case PROCUREMENT_ACTION_TYPES.CONTACTS.FETCH:
      return {
        ...state,
        contacts: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case PROCUREMENT_ACTION_TYPES.CONTACTS.FETCHED:
      return {
        ...state,
        contacts: action.contacts,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case PROCUREMENT_ACTION_TYPES.CONTACT.FETCHED:
    case PROCUREMENT_ACTION_TYPES.CONTACT.UPDATED:
      return { ...state, currentContact: action.contact };
    case PROCUREMENT_ACTION_TYPES.CONTACT.CREATED:
      return { ...state, contacts: [...state.contacts, action.contact] };
    case PROCUREMENT_ACTION_TYPES.CONTACT.DESTROYED:
    case PROCUREMENT_ACTION_TYPES.CONTACT.UPDATE:
    case PROCUREMENT_ACTION_TYPES.CONTACT.CREATE:
      return { ...state, currentContact: undefined };
    default:
      return state;
  }
}

interface ContractsState extends PaginatedAPIResponse {
  searchResults: Contract[]
  contracts: Contract[]
  currentContract:Contract
  loading: boolean
}

const initialContractsState:ContractsState = {
  searchResults: [],
  contracts: [],
  currentContract: undefined,
  loading: false,
};

type ContractReducerActions = | ContractAction | ProfileFetchedAction;

export function contracts(
  state:ContractsState = initialContractsState,
  action:ContractReducerActions = undefined,
) {
  switch (action.type) {
    case PROCUREMENT_ACTION_TYPES.CONTRACTS.FETCH:
      return {
        ...state,
        contracts: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case PROCUREMENT_ACTION_TYPES.CONTRACTS.FETCHED:
      return {
        ...state,
        contracts: action.contracts,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        pageSize: action.pageSize,
        results: action.results,
        loading: false,
      };
    case PROCUREMENT_ACTION_TYPES.CONTRACT.FETCHED:
    case PROCUREMENT_ACTION_TYPES.CONTRACT.UPDATED:
      return { ...state, currentContract: action.contract };
    case PROCUREMENT_ACTION_TYPES.CONTRACT.CREATED:
      return { ...state, contracts: [...state.contracts, action.contract] };
    case PROCUREMENT_ACTION_TYPES.CONTRACT.DESTROYED:
    case PROCUREMENT_ACTION_TYPES.CONTRACT.UPDATE:
    case PROCUREMENT_ACTION_TYPES.CONTRACT.CREATE:
      return { ...state, currentContract: undefined };
    default:
      return state;
  }
}
