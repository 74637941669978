import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  FetchSuppliersAction,
  FetchSupplierAction,
  CreateSupplierAction,
  UpdateSupplierAction,
  DestroySupplierAction,
  SearchSuppliersAction,
} from '@store/procurement/suppliers/types';
import {
  suppliersFetched,
  supplierFetched,
  supplierCreated,
  supplierUpdated,
  supplierDestroyed,
  suppliersSearched,
} from '@store/procurement/suppliers/actions';
import { Observable } from 'rxjs';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchSuppliersEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.SUPPLIERS.FETCH),
  switchMap((action:FetchSuppliersAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/suppliers/`,
    method: 'GET',
    success: suppliersFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchSupplierEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.SUPPLIER.FETCH),
  switchMap((action:FetchSupplierAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/suppliers/${action.id}/`,
    method: 'GET',
    success: supplierFetched,
    auth: true,
    state$,
    action,
  })),
);

const createSupplierEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.SUPPLIER.CREATE),
  switchMap((action:CreateSupplierAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/suppliers/`,
    method: 'POST',
    success: supplierCreated,
    auth: true,
    state$,
    action,
    body: action.supplier,
  })),
);

const updateSupplierEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.SUPPLIER.UPDATE),
  switchMap((action:UpdateSupplierAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/suppliers/${action.id}/`,
    method: 'PUT',
    success: supplierUpdated,
    auth: true,
    state$,
    action,
    body: action.supplier,
  })),
);

const destroySupplierEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.SUPPLIER.DESTROY),
  switchMap((action:DestroySupplierAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/suppliers/${action.id}/`,
    method: 'DELETE',
    success: supplierDestroyed,
    auth: true,
    state$,
    action,
  })),
);

const searchSuppliersEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.SUPPLIERS.SEARCH),
  switchMap((action:SearchSuppliersAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/suppliers/`,
    method: 'POST',
    success: suppliersSearched,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchSuppliersEpic,
  fetchSupplierEpic,
  createSupplierEpic,
  updateSupplierEpic,
  destroySupplierEpic,
  searchSuppliersEpic,
];
