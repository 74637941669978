import { PaginatedAPIResponse } from '@store/application/types';
import { PATRON_ACTION_TYPES } from '@store/patrons/actionTypes';
import { PatronGroup, PatronGroupAction } from '@store/patrons/groups/types';
import { Patron, PatronAction } from '@store/patrons/patrons/types';

interface PatronsState extends PaginatedAPIResponse {
  searchResults: Patron[]
  patrons: Patron[]
  currentPatron: Patron
  loading: boolean
  currentPage?: number
  totalPages?: number
  pageSize?: number
}

const initialPatronsState:PatronsState = {
  searchResults: [],
  patrons: [],
  currentPatron: undefined,
  loading: false,
};

export function patrons(
  state:PatronsState = initialPatronsState,
  action:PatronAction|PatronGroupAction = undefined,
) {
  switch (action.type) {
    case PATRON_ACTION_TYPES.PATRONS.FETCH:
      return {
        ...state,
        patrons: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case PATRON_ACTION_TYPES.PATRON_GROUP.MEMBERSHIPS_CREATED:
      return {
        ...state,
        patrons: [...state.patrons, ...action.patrons],
        loading: false,
      };
    case PATRON_ACTION_TYPES.PATRONS.FETCHED:
      return {
        ...state,
        patrons: action.patrons,
        loading: false,
      };
    case PATRON_ACTION_TYPES.PATRON.FETCHED:
    case PATRON_ACTION_TYPES.PATRON.UPDATED:
      return { ...state, currentPatron: action.patron };
    case PATRON_ACTION_TYPES.PATRON.CREATED:
      return { ...state, patrons: [...state.patrons, action.patron] };
    case PATRON_ACTION_TYPES.PATRONS.SEARCH:
    case PATRON_ACTION_TYPES.PATRONS.CLEAR_SEARCH:
      return { ...state, searchResults: [] };
    case PATRON_ACTION_TYPES.PATRONS.SEARCHED:
      return { ...state, searchResults: action.results };
    case PATRON_ACTION_TYPES.PATRON.DESTROYED:
    case PATRON_ACTION_TYPES.PATRON.UPDATE:
    case PATRON_ACTION_TYPES.PATRON.CREATE:
    case PATRON_ACTION_TYPES.PATRON.CLEAR:
      return { ...state, currentPatron: undefined };
    default:
      return state;
  }
}

interface PatronGroupsState extends PaginatedAPIResponse {
  searchResults: PatronGroup[]
  groups: PatronGroup[]
  currentPatronGroup:PatronGroup
  loading: boolean
  currentPage?: number
  totalPages?: number
  pageSize?: number
}

const initialPatronGroupsState:PatronGroupsState = {
  searchResults: [],
  groups: [],
  currentPatronGroup: undefined,
  loading: false,
};

export function groups(
  state:PatronGroupsState = initialPatronGroupsState,
  action:PatronGroupAction = undefined,
) {
  switch (action.type) {
    case PATRON_ACTION_TYPES.PATRON_GROUPS.FETCH:
      return {
        ...state,
        groups: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case PATRON_ACTION_TYPES.PATRON_GROUPS.FETCHED:
      return {
        ...state,
        groups: action.groups,
        loading: false,
      };
    case PATRON_ACTION_TYPES.PATRON_GROUPS.SEARCHED:
      console.log(action.groups);
      return { ...state, searchResults: action.groups };
    case PATRON_ACTION_TYPES.PATRON_GROUP.FETCHED:
    case PATRON_ACTION_TYPES.PATRON_GROUP.UPDATED:
      return { ...state, currentPatronGroup: action.group };
    case PATRON_ACTION_TYPES.PATRON_GROUP.CREATED:
      return { ...state, groups: [...state.groups, action.group] };
    case PATRON_ACTION_TYPES.PATRON_GROUP.DESTROYED:
    case PATRON_ACTION_TYPES.PATRON_GROUP.UPDATE:
    case PATRON_ACTION_TYPES.PATRON_GROUP.CREATE:
      return { ...state, currentPatronGroup: undefined };
    default:
      return state;
  }
}

// export default [
//   patrons,
//   groups,
// ];
