import {
  LoadSessionAction,
  LoginAction,
  LogoutAction,
  DestroySessionAction,
  ForgotPasswordAction,
  ForgotPasswordSuccessAction,
  ResetPasswordAction,
  SessionCreatedAction,
  ConfirmEmailAction,
  SessionLoadedAction,
} from '@store/authentication/types';
import { AUTHENTICATION_ACTION_TYPES } from '@store/authentication/actionTypes';

export function loadSession():LoadSessionAction {
  return {
    type: AUTHENTICATION_ACTION_TYPES.SESSION.LOAD,
  };
}

export function sessionLoaded(data:any):SessionLoadedAction {
  return {
    type: AUTHENTICATION_ACTION_TYPES.SESSION.LOADED,
    apiKey: data.apiKey,
  };
}

export function login(credentials:FormData):LoginAction {
  return {
    type: AUTHENTICATION_ACTION_TYPES.LOGIN,
    credentials,
  };
}

export function logout():LogoutAction {
  return {
    type: AUTHENTICATION_ACTION_TYPES.LOGOUT,
  };
}

export function sessionCreated(response:any):SessionCreatedAction {
  return {
    type: AUTHENTICATION_ACTION_TYPES.SESSION.CREATED,
    apiKey: response.apiKey,
  };
}

export function destroySession(id:string):DestroySessionAction {
  return {
    type: AUTHENTICATION_ACTION_TYPES.SESSION.DESTROY,
    id,
  };
}

export function forgotPassword(data:FormData):ForgotPasswordAction {
  return {
    type: AUTHENTICATION_ACTION_TYPES.FORGOT_PASSWORD,
    data,
  };
}

export function forgotPasswordSuccess():ForgotPasswordSuccessAction {
  return {
    type: AUTHENTICATION_ACTION_TYPES.FORGOT_PASSWORD_SUCCESS,
  };
}

export function resetPassword(data:FormData):ResetPasswordAction {
  return {
    type: AUTHENTICATION_ACTION_TYPES.RESET_PASSWORD,
    data,
  };
}

export function confirmEmail(data:FormData):ConfirmEmailAction {
  return {
    type: AUTHENTICATION_ACTION_TYPES.CONFIRM_EMAIL,
    data,
  };
}
