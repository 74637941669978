import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  FetchFundsAction,
  FetchFundAction,
  CreateFundAction,
  UpdateFundAction,
  DestroyFundAction,
} from '@store/procurement/funds/types';
import {
  fundsFetched,
  fundFetched,
  fundCreated,
  fundUpdated,
  fundDestroyed,
} from '@store/procurement/funds/actions';
import { Observable } from 'rxjs';
import { Action } from 'redux';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const fetchFundsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.FUNDS.FETCH),
  switchMap((action:FetchFundsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/funds/`,
    method: 'GET',
    success: fundsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchFundEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.FUND.FETCH),
  switchMap((action:FetchFundAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/funds/${action.id}/`,
    method: 'GET',
    success: fundFetched,
    auth: true,
    state$,
    action,
  })),
);

const createFundEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.FUND.CREATE),
  switchMap((action:CreateFundAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/funds/`,
    method: 'POST',
    success: fundCreated,
    auth: true,
    state$,
    action,
    body: action.fund,
  })),
);

const updateFundEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.FUND.UPDATE),
  switchMap((action:UpdateFundAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/funds/${action.id}/`,
    method: 'PUT',
    success: fundUpdated,
    auth: true,
    state$,
    action,
    body: action.fund,
  })),
);

const destroyFundEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.FUND.DESTROY),
  switchMap((action:DestroyFundAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/funds/${action.id}/`,
    method: 'DELETE',
    success: fundDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchFundsEpic,
  fetchFundEpic,
  createFundEpic,
  updateFundEpic,
  destroyFundEpic,
];
