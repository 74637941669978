import { NOTIFICATION_ACTION_TYPES } from '@store/notifications/actionTypes';

import {
  CreateNotificationAction,
  DestroyNotificationAction,
  FetchNotificationAction,
  FetchNotificationsAction,
  FetchNotificationsOptions,
  Notification,
  NotificationCreatedAction,
  NotificationDestroyedAction,
  NotificationFetchedAction,
  NotificationsFetchedAction,
  NotificationUpdatedAction,
  UpdateNotificationAction,
} from '@store/notifications/types';

export const fetchNotifications = (
  options:FetchNotificationsOptions = undefined,
):FetchNotificationsAction => ({
  type: NOTIFICATION_ACTION_TYPES.NOTIFICATIONS.FETCH,
  options,
});

export const notificationsFetched = (
  response:Notification[],
):NotificationsFetchedAction => ({
  type: NOTIFICATION_ACTION_TYPES.NOTIFICATIONS.FETCHED,
  notifications: response,
});

export const fetchNotification = (id:number|string):FetchNotificationAction => ({
  type: NOTIFICATION_ACTION_TYPES.NOTIFICATION.FETCH,
  id,
});

export const notificationFetched = (notification:Notification):NotificationFetchedAction => ({
  type: NOTIFICATION_ACTION_TYPES.NOTIFICATION.FETCHED,
  notification,
});

export const createNotification = (notification:FormData):CreateNotificationAction => ({
  type: NOTIFICATION_ACTION_TYPES.NOTIFICATION.CREATE,
  notification,
});

export const notificationCreated = (notification:Notification):NotificationCreatedAction => ({
  type: NOTIFICATION_ACTION_TYPES.NOTIFICATION.CREATED,
  notification,
  id: notification.id,
});

export const updateNotification = (notification:FormData):UpdateNotificationAction => ({
  type: NOTIFICATION_ACTION_TYPES.NOTIFICATION.UPDATE,
  notification,
  id: notification.get('id') as string|number,
});

export const notificationUpdated = (notification:Notification):NotificationUpdatedAction => ({
  type: NOTIFICATION_ACTION_TYPES.NOTIFICATION.UPDATED,
  notification,
  id: notification.id,
});

export const destroyNotification = (notification:FormData):DestroyNotificationAction => ({
  type: NOTIFICATION_ACTION_TYPES.NOTIFICATION.DESTROY,
  id: notification.get('id') as string|number,
});

export const notificationDestroyed = (id:number|string):NotificationDestroyedAction => ({
  type: NOTIFICATION_ACTION_TYPES.NOTIFICATION.DESTROYED,
  id,
});
