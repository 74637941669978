import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { CATALOG_ACTION_TYPES } from '@store/catalog/actionTypes';
import {
  CreateRecordAction,
  DestroyRecordAction,
  FetchRecordsAction,
  FetchRecordAction,
  UpdateRecordAction,
  SearchByISBNAction,
  // AddRecordTolistAction,
  AddRecordsTolistAction,
  DestroyRecordsAction,
  SearchRecordsAction,
  ImportRecordsAction,
} from '@store/catalog/records/types';
import {
  Observable,
} from 'rxjs';

import {
  recordDestroyed,
  recordsFetched,
  recordFetched,
  recordUpdated,
  recordsSearched,
  // ISBNSearchCompleted,
  // recordAddedToList,
  recordsAddedToList,
  recordsDestroyed,
  recordsImported,
  // ISBNSearchCompleted,
  recordCreated,
} from '@store/catalog/records/actions';
import { Action } from 'redux';
import { handleAJAXRequest } from '@store/application/APIHelper';
import { RootStateOrAny } from 'react-redux';
import CONFIG from '@config/environments/base';

const importRecordsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORDS.IMPORT),
  switchMap((action:ImportRecordsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/records/`,
    method: 'POST',
    success: recordsImported,
    auth: true,
    state$,
    action,
    body: action.records,
  })),
);

const fetchRecordsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORDS.FETCH),
  switchMap((action:FetchRecordsAction):Observable<any> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/records/`,
    method: 'GET',
    success: recordsFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchRecordEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD.FETCH),
  switchMap((action:FetchRecordAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/records/${action.id}/`,
    method: 'GET',
    success: recordFetched,
    auth: true,
    state$,
    action,
  })),
);

const createRecordEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD.CREATE),
  switchMap(
    (action:CreateRecordAction):Observable<any> => handleAJAXRequest({
      url: `${CONFIG.CATALOG_URL}/records/`,
      method: 'POST',
      success: recordCreated,
      auth: true,
      state$,
      action,
      body: action.record,
    }),
  ),
);

const updateRecordEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD.UPDATE),
  switchMap((action:UpdateRecordAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/records/${action.id}/`,
    method: 'PUT',
    success: recordUpdated,
    auth: true,
    state$,
    action,
    body: action.record,
  })),
);

const destroyRecordEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD.DESTROY),
  switchMap((action:DestroyRecordAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/records/${action.id}/`,
    method: 'DELETE',
    success: recordDestroyed,
    auth: true,
    state$,
    action,
  })),
);

const destroyRecordsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORDS.DESTROY),
  switchMap((action:DestroyRecordsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/records/`,
    method: 'DELETE',
    success: recordsDestroyed,
    auth: true,
    state$,
    action,
    body: action.records,
  })),
);

const searchRecordsEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORDS.SEARCH),
  switchMap((action:SearchRecordsAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/records/`,
    method: 'POST',
    success: recordsSearched,
    auth: true,
    state$,
    action,
  })),
);

const searchRecordMetadataByISBNEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.RECORD.SEARCH_BY_ISBN),
  switchMap((action:SearchByISBNAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/records/`,
    method: 'POST',
    success: recordsSearched,
    auth: true,
    state$,
    action,
  })),
);

// const fetchSearchResultsEpic = (
//   action$:any,
// ) => action$.pipe(
//   ofType(CATALOG_ACTION_TYPES.RECORDS.FETCH_SEARCH_RESULTS),
//   switchMap((action:SearchRecordsAction):Observable<Action> => handleAJAXRequest({
//     url: `${CONFIG.CATALOG_URL}/records/`,
//     method: 'POST',
//     success: recordImageCreated,
//     auth: true,
//     state$,
//     action,
//     body: action.recordImage,
//   })),
// );

// const addRecordToListEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
//   ofType(CATALOG_ACTION_TYPES.LIST.ADD_RECORD),
//   switchMap((action:AddRecordTolistAction):Observable<Action> => ajax({
//     url: `${CONFIG.API_URL}/catalog/records/record.json`,
//     method: 'GET',
//     headers: fetchAuthHeaders(state$),
//   }).pipe(
//     map(
//       (response:AjaxResponse) => recordAddedToList(response.response),
//     ),
//     catchError(
//       (error):ObservableInput<Action> => handleAPIErrors(action, error),
//     ),
//   )),
// );

const addRecordsToListEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(CATALOG_ACTION_TYPES.LIST.ADD_RECORDS),
  switchMap((action:AddRecordsTolistAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.CATALOG_URL}/list/${action.listId}/records/`,
    method: 'POST',
    success: recordsAddedToList,
    auth: true,
    state$,
    action,
    body: action.records,
  })),
);

export default [
  importRecordsEpic,
  fetchRecordsEpic,
  fetchRecordEpic,
  createRecordEpic,
  updateRecordEpic,
  destroyRecordEpic,
  destroyRecordsEpic,
  searchRecordsEpic,
  searchRecordMetadataByISBNEpic,
  // fetchSearchResultsEpic,
  // addRecordToListEpic,
  addRecordsToListEpic,
];
