import { handleAJAXRequest } from '@store/application/APIHelper';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  FetchPurchasesAction,
  FetchPurchaseAction,
  CreatePurchaseAction,
  UpdatePurchaseAction,
  DestroyPurchaseAction,
} from '@store/procurement/purchases/types';
import {
  purchasesFetched,
  purchaseFetched,
  purchaseCreated,
  purchaseUpdated,
  purchaseDestroyed,
} from '@store/procurement/purchases/actions';
import { Observable } from 'rxjs';
import { Action } from 'redux';
import CONFIG from '@config/environments/base';
import { RootStateOrAny } from 'react-redux';

const fetchPurchasesEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.PURCHASES.FETCH),
  switchMap((action:FetchPurchasesAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/purchases/`,
    method: 'GET',
    success: purchasesFetched,
    auth: true,
    state$,
    action,
  })),
);

const fetchPurchaseEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.PURCHASE.FETCH),
  switchMap((action:FetchPurchaseAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/purchases/${action.id}/`,
    method: 'GET',
    success: purchaseFetched,
    auth: true,
    state$,
    action,
  })),
);

// !!!
// const fetchPurchaseByRecordInstanceEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
//   ofType(PROCUREMENT_ACTION_TYPES.PURCHASE.FETCH_BY_RECORD_INSTANCE),
//   switchMap((action:FetchPurchaseAction):Observable<Action> => {
//   const model = new PurchaseModel(Object.fromEntries(action.purchase));
//     return AmplifyAPI
//       .save(model)
//       .pipe(
//     map(
//       (response:AjaxResponse) => purchaseFetchedByRecordInstance(response.response),
//     ),
//     catchError(
//       (error):ObservableInput<Action> => handleAPIErrors(action, error),
//     ),
//   )}),
// );

const createPurchaseEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.PURCHASE.CREATE),
  switchMap((action:CreatePurchaseAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/purchases/`,
    method: 'POST',
    success: purchaseCreated,
    auth: true,
    state$,
    action,
    body: action.purchase,
  })),
);

const updatePurchaseEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.PURCHASE.UPDATE),
  switchMap((action:UpdatePurchaseAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/purchases/${action.id}/`,
    method: 'PUT',
    success: purchaseUpdated,
    auth: true,
    state$,
    action,
    body: action.purchase,
  })),
);

const destroyPurchaseEpic = (action$:any, state$:RootStateOrAny) => action$.pipe(
  ofType(PROCUREMENT_ACTION_TYPES.PURCHASE.DESTROY),
  switchMap((action:DestroyPurchaseAction):Observable<Action> => handleAJAXRequest({
    url: `${CONFIG.PROCUREMENT_URL}/purchases/${action.id}/`,
    method: 'DELETE',
    success: purchaseDestroyed,
    auth: true,
    state$,
    action,
  })),
);

export default [
  fetchPurchasesEpic,
  fetchPurchaseEpic,
  // fetchPurchaseByRecordInstanceEpic,
  createPurchaseEpic,
  updatePurchaseEpic,
  destroyPurchaseEpic,
];
