interface IORGANIZATION_ACTION_TYPES {
  ORGANIZATIONS: {
    FETCH: 'disembark/organizations/fetch_organizations',
    FETCHED: 'disembark/organizations/organizations_fetched',
  },
  ORGANIZATION: {
    FETCH: 'disembark/organizations/fetch_organization',
    FETCHED: 'disembark/organizations/organization_fetched',
    CREATE: 'disembark/organizations/create_organization',
    CREATED: 'disembark/organizations/organization_created',
    UPDATE: 'disembark/organizations/update_organization',
    UPDATED: 'disembark/organizations/organization_updated',
    DESTROY: 'disembark/organizations/destroy_organization',
    DESTROYED: 'disembark/organizations/organization_destroyed',
    CHECK_SHORT_NAME: 'disembark/organizations/check_short_name',
    SHORT_NAME_CHECKED: 'disembark/organizations/short_name_checked',
  },
  BRANCHES: {
    FETCH: 'disembark/organizations/fetch_branches',
    FETCHED: 'disembark/organizations/branches_fetched',
    SEARCH: 'disembark/organizations/search_branches',
    SEARCHED: 'disembark/organizations/branches_searched',
  },
  BRANCH: {
    FETCH: 'disembark/organizations/fetch_branch',
    FETCHED: 'disembark/organizations/branch_fetched',
    CREATE: 'disembark/organizations/create_branch',
    CREATED: 'disembark/organizations/branch_created',
    UPDATE: 'disembark/organizations/update_branch',
    UPDATED: 'disembark/organizations/branch_updated',
    DESTROY: 'disembark/organizations/destroy_branch',
    DESTROYED: 'disembark/organizations/branch_destroyed',
    CLEAR: 'disembark/organizations/clear_branch',
  },
  STAFF_ADMINS: {
    FETCH: 'disembark/organizations/fetch_admins',
    FETCHED: 'disembark/organizations/admins_fetched',
  },
  STAFF_ADMIN: {
    FETCH: 'disembark/organizations/fetch_admin',
    FETCHED: 'disembark/organizations/admin_fetched',
    CREATE: 'disembark/organizations/create_admin',
    CREATED: 'disembark/organizations/admin_created',
    UPDATE: 'disembark/organizations/update_admin',
    UPDATED: 'disembark/organizations/admin_updated',
    DESTROY: 'disembark/organizations/destroy_admin',
    DESTROYED: 'disembark/organizations/admin_destroyed'
  }
}

export const ORGANIZATION_ACTION_TYPES:IORGANIZATION_ACTION_TYPES = {
  ORGANIZATIONS: {
    FETCH: 'disembark/organizations/fetch_organizations',
    FETCHED: 'disembark/organizations/organizations_fetched',
  },
  ORGANIZATION: {
    FETCH: 'disembark/organizations/fetch_organization',
    FETCHED: 'disembark/organizations/organization_fetched',
    CREATE: 'disembark/organizations/create_organization',
    CREATED: 'disembark/organizations/organization_created',
    UPDATE: 'disembark/organizations/update_organization',
    UPDATED: 'disembark/organizations/organization_updated',
    DESTROY: 'disembark/organizations/destroy_organization',
    DESTROYED: 'disembark/organizations/organization_destroyed',
    CHECK_SHORT_NAME: 'disembark/organizations/check_short_name',
    SHORT_NAME_CHECKED: 'disembark/organizations/short_name_checked',
  },
  BRANCHES: {
    FETCH: 'disembark/organizations/fetch_branches',
    FETCHED: 'disembark/organizations/branches_fetched',
    SEARCH: 'disembark/organizations/search_branches',
    SEARCHED: 'disembark/organizations/branches_searched',
  },
  BRANCH: {
    FETCH: 'disembark/organizations/fetch_branch',
    FETCHED: 'disembark/organizations/branch_fetched',
    CREATE: 'disembark/organizations/create_branch',
    CREATED: 'disembark/organizations/branch_created',
    UPDATE: 'disembark/organizations/update_branch',
    UPDATED: 'disembark/organizations/branch_updated',
    DESTROY: 'disembark/organizations/destroy_branch',
    DESTROYED: 'disembark/organizations/branch_destroyed',
    CLEAR: 'disembark/organizations/clear_branch',
  },
  STAFF_ADMINS: {
    FETCH: 'disembark/organizations/fetch_admins',
    FETCHED: 'disembark/organizations/admins_fetched',
  },
  STAFF_ADMIN: {
    FETCH: 'disembark/organizations/fetch_admin',
    FETCHED: 'disembark/organizations/admin_fetched',
    CREATE: 'disembark/organizations/create_admin',
    CREATED: 'disembark/organizations/admin_created',
    UPDATE: 'disembark/organizations/update_admin',
    UPDATED: 'disembark/organizations/admin_updated',
    DESTROY: 'disembark/organizations/destroy_admin',
    DESTROYED: 'disembark/organizations/admin_destroyed',
  },
};

export default ORGANIZATION_ACTION_TYPES;
