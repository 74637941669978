import { PROCUREMENT_ACTION_TYPES } from '@store/procurement/actionTypes';
import {
  CreateFundAction,
  DestroyFundAction,
  FetchFundAction,
  FetchFundsAction,
  FetchFundsOptions,
  Fund,
  FundCreatedAction,
  FundDestroyedAction,
  FundFetchedAction,
  FundsFetchedAction,
  FundUpdatedAction,
  UpdateFundAction,
} from '@store/procurement/funds/types';

export const fetchFunds = (options:FetchFundsOptions = undefined):FetchFundsAction => ({
  type: PROCUREMENT_ACTION_TYPES.FUNDS.FETCH,
  options,
});

export const fundsFetched = (response:Fund[]):FundsFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.FUNDS.FETCHED,
  funds: response,
});

export const fetchFund = (id:number|string):FetchFundAction => ({
  type: PROCUREMENT_ACTION_TYPES.FUND.FETCH,
  id,
});

export const fundFetched = (fund:Fund):FundFetchedAction => ({
  type: PROCUREMENT_ACTION_TYPES.FUND.FETCHED,
  fund,
});

export const createFund = (fund:FormData):CreateFundAction => ({
  type: PROCUREMENT_ACTION_TYPES.FUND.CREATE,
  fund,
});

export const fundCreated = (fund:Fund):FundCreatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.FUND.CREATED,
  fund,
  id: fund.id,
});

export const updateFund = (fund:FormData):UpdateFundAction => ({
  type: PROCUREMENT_ACTION_TYPES.FUND.UPDATE,
  fund,
  id: fund.get('id') as string|number,
});

export const fundUpdated = (fund:Fund):FundUpdatedAction => ({
  type: PROCUREMENT_ACTION_TYPES.FUND.UPDATED,
  fund,
  id: fund.id,
});

export const destroyFund = (fund:FormData):DestroyFundAction => ({
  type: PROCUREMENT_ACTION_TYPES.FUND.DESTROY,
  id: fund.get('id') as string|number,
});

export const fundDestroyed = (id:number|string):FundDestroyedAction => ({
  type: PROCUREMENT_ACTION_TYPES.FUND.DESTROYED,
  id,
});
