import { PaginatedAPIResponse } from '@store/application/types';
import { PROFILE_ACTION_TYPES } from '@store/profile/actionTypes';
import { Association, AssociationAction } from '@store/profile/associations/types';
import { FamilyMember, FamilyMemberAction } from '@store/profile/family/types';
import { Profile, ProfileAction } from '@store/profile/profile/types';

const initialProfileState:Profile = {
  isFetched: false,
  defaultOrganization: undefined,
  id: undefined,
  provider: undefined,
  uid: undefined,
  allow_password_change: undefined,
  name: undefined,
  nickname: undefined,
  image: undefined,
  email: undefined,
  created_at: undefined,
  updated_at: undefined,
};

export function profile(
  state:Profile = initialProfileState,
  action:ProfileAction = undefined,
):Profile {
  switch (action.type) {
    case PROFILE_ACTION_TYPES.PROFILE.FETCH:
      return { ...state, isFetched: false };
    case PROFILE_ACTION_TYPES.PROFILE.FETCHED:
      return Object.assign(state, action.response, {
        isFetched: true,
      });
    default:
      return state;
  }
}

interface AssociationsState extends PaginatedAPIResponse {
  associations: Association[]
  currentAssociation:any
  loading: boolean
}

const initialAssociationsState:AssociationsState = {
  associations: [],
  currentAssociation: {},
  loading: false,
};

export function associations(
  state:AssociationsState = initialAssociationsState,
  action:AssociationAction = undefined,
):AssociationsState {
  switch (action.type) {
    case PROFILE_ACTION_TYPES.ASSOCIATIONS.FETCH:
      return {
        ...state,
        associations: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case PROFILE_ACTION_TYPES.ASSOCIATIONS.FETCHED:
      return {
        ...state,
        associations: action.associations,
        loading: false,
      };
    case PROFILE_ACTION_TYPES.ASSOCIATION.FETCHED:
    case PROFILE_ACTION_TYPES.ASSOCIATION.UPDATED:
      return { ...state, currentAssociation: action.association };
    case PROFILE_ACTION_TYPES.ASSOCIATION.CREATED:
      return { ...state, associations: [...state.associations, action.association] };
    case PROFILE_ACTION_TYPES.ASSOCIATION.DESTROYED:
    case PROFILE_ACTION_TYPES.ASSOCIATION.UPDATE:
    case PROFILE_ACTION_TYPES.ASSOCIATION.CREATE:
      return { ...state, currentAssociation: {} };
    default:
      return state;
  }
}

interface FamilyMembersState extends PaginatedAPIResponse {
  searchResults: FamilyMember[]
  familyMembers: FamilyMember[]
  currentFamilyMember:FamilyMember
  loading: boolean
}

const initialFamilyMembersState:FamilyMembersState = {
  searchResults: [],
  familyMembers: [],
  currentFamilyMember: undefined,
  loading: false,
};

export function familyMembers(
  state:FamilyMembersState = initialFamilyMembersState,
  action:FamilyMemberAction = undefined,
):FamilyMembersState {
  switch (action.type) {
    case PROFILE_ACTION_TYPES.FAMILY_MEMBERS.FETCH:
      return {
        ...state,
        familyMembers: [],
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        results: 0,
        loading: true,
      };
    case PROFILE_ACTION_TYPES.FAMILY_MEMBERS.FETCHED:
      return {
        ...state,
        familyMembers: action.familyMembers,
        loading: false,
      };
    case PROFILE_ACTION_TYPES.FAMILY_MEMBER.FETCHED:
    case PROFILE_ACTION_TYPES.FAMILY_MEMBER.UPDATED:
      return { ...state, currentFamilyMember: action.familyMember };
    case PROFILE_ACTION_TYPES.FAMILY_MEMBER.CREATED:
      return { ...state, familyMembers: [...state.familyMembers, action.familyMember] };
    case PROFILE_ACTION_TYPES.FAMILY_MEMBER.DESTROYED:
    case PROFILE_ACTION_TYPES.FAMILY_MEMBER.UPDATE:
    case PROFILE_ACTION_TYPES.FAMILY_MEMBER.CREATE:
      return { ...state, currentFamilyMember: undefined };
    default:
      return state;
  }
}
