import GROUPINGS from '@store/application/actionGroupings';

export interface ApplicationMessage {
  type: string
  action: string
  resource: string
  id?: number|string
  target?: string
  errors?: string[]
  state: string
}

const initialApplicationState:ApplicationMessage[] = [];

export default function reducer(
  state:any[] = initialApplicationState,
  action:ApplicationMessage = undefined,
) {
  switch (true) {
    // Skip if @@
    // @@ is used for internal actions such as @@redux
    // and @@INIT
    case GROUPINGS.APP.test(action.type):
      return state;
    // Warn if an action doesn't have an ID when
    // an ID is required for adding/removing from the queue
    case GROUPINGS.REQUIRES_ID.test(action.type):
      if (action.id === undefined) {
        console.group('ERROR: Missing application action parameters.');
        console.warn('---- ERROR ----');
        console.warn('The following action does not have a required ID:');
        console.warn(action);
        console.groupEnd();
      }
    // break omitted - Add a pending action to the queue
    case GROUPINGS.PENDING.test(action.type):
      return [...state.filter((item) => !((item.state === 'completed' || item.state === 'error') && item.resource === action.resource && item.id === action.id)), {
        action: action.type,
        resource: action.resource,
        id: action.id,
        state: 'pending',
      }];
    // Remove one pending state of the same resource type
    case GROUPINGS.COMPLETED.test(action.type):
      return [...state.filter((item, index) => index !== state.findIndex((object) => object.resource === action.resource && object.state === 'pending')), {
        action: action.type,
        resource: action.resource,
        id: action.id,
        state: 'completed',
      }];
    // Add an error to the queue (for display purposes)
    case GROUPINGS.ERROR.test(action.type):
      return [...state.filter((item) => item.state !== 'pending'), {
        action: action.type,
        target: action.action,
        errors: action.errors,
        state: 'error',
      }];
    // Events not associated with an epic,
    // so an application specific event
    case GROUPINGS.ONEWAY.test(action.type):
      return state.filter((item) => item.state !== 'error');
    // Performing a new action will clear errors
    default:
      // Warn if an action is unrecognized
      console.warn('---- WARNING ----');
      console.warn('The following action does not match an application event:');
      console.warn(action);
      return state.filter((item) => item.state !== 'error');
  }
}
